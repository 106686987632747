<template>
  <div class="template-editor">
    <div class="title-header">
      <div class="title-header-text">视频模板编辑</div>
      <div class="flex-row-center">
        <el-button
          :disabled="!canUndo"
          @click="undo"
        >
          <i class="el-icon-refresh-left"></i> 撤销
        </el-button>
        <el-button
          :disabled="!canRedo"
          @click="redo"
        >
          <i class="el-icon-refresh-right"></i> 恢复
        </el-button>
        <el-button
          type="primary"
          class="gradient-button"
          @click="saveTemplate"
        >视频制作</el-button>
      </div>
    </div>
    <div class="editor-content">
      <!-- 左侧菜单栏 -->
      <div class="editor-menu">
        <div
          v-for="(menu, index) in menus"
          :key="index"
          class="menu-item"
          :class="{ active: currentMenu === menu.type }"
          @click="selectMenu(menu.type)"
        >
          <i :class="menu.icon"></i>
          <span>{{ menu.title }}</span>
        </div>
        <!-- <el-button
          v-if="currentMenu !== 'music'"
          class="add-button"
          @click="addElementByType(currentMenu)"
        >
          <i class="el-icon-plus"></i> 添加{{ getMenuTitle(currentMenu) }}
        </el-button> -->
      </div>

      <!-- 中间编辑区域 -->
      <div class="editor-main">
        <div class="editor-canvas-wrapper">
          <div
            class="editor-canvas"
            :style="canvasStyle"
          >
            <!-- 渲染每个元素 -->
            <template v-for="(element, index) in pageList">
              <div
                v-if="element.type === 'text'"
                :key="`text-${element.id || index}`"
                :class="{ active: selectedElement === element }"
                :style="getElementStyle(element)"
                v-draggable
                @click="selectElement(element)"
              >
                <!-- class="element text-element relative" -->
                <i
                  class="el-icon-close delete-icon"
                  @click.stop="deleteElement(element)"
                ></i>
                <div v-html="element.content || ''"></div>
                <!-- {{ element.content || '' }} -->
              </div>

              <div
                v-else-if="element.type === 'file'"
                :key="`image-${element.id || index}`"
                :class="{ active: selectedElement === element }"
                :style="getElementStyle(element)"
                v-draggable
                @click="selectElement(element)"
              >
                <!-- class="element image-element relative" -->
                <i
                  class="el-icon-close delete-icon"
                  @click.stop="deleteElement(element)"
                ></i>
                <img :src="element.url" />
              </div>

              <div
                v-else-if="element.type === 'video'"
                :key="`video-${element.id || index}`"
                class="element video-element relative"
                :class="{ active: selectedElement === element }"
                :style="getElementStyle(element)"
                v-draggable
                @click="selectElement(element)"
              >
                <i
                  class="el-icon-close delete-icon"
                  @click.stop="deleteElement(element)"
                ></i>
                <video
                  v-if="element.propsValue && element.propsValue.videoSrc && element.propsValue.videoSrc.url"
                  :src="element.propsValue.videoSrc.url"
                  controls
                ></video>
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- 右侧属性面板 -->
      <div class="editor-panel">
        <div class="panel-header">
          <span>{{ currentMenu ? getMenuTitle(currentMenu) : '元素属性' }}</span>
          <el-button
            type="primary"
            plain
            size="small"
            v-if="currentMenu && currentMenu !== 'music'"
            @click="addElementByType(currentMenu)"
          >
            <i class="el-icon-plus"></i> 添加{{ getMenuTitle(currentMenu) }}
          </el-button>
        </div>

        <!-- 音乐空面板 -->
        <div
          v-if="currentMenu === 'music' && !selectedElement"
          class="empty-panel"
        >
          <div class="empty-tip">当前未设置背景音乐</div>
          <el-button
            type="primary"
            plain
            @click="chooseBgm"
          >
            <i class="el-icon-plus"></i> 添加背景音乐
          </el-button>
        </div>

        <template v-else-if="selectedElement">
          <!-- 文本属性编辑 -->
          <template v-if="selectedElement.type === 'text'">
            <div class="panel-item">
              <div class="item-label">文本内容</div>
              <el-input
                v-model="selectedElement.propsValue.text"
                type="textarea"
                maxlength="200"
                show-word-limit
              />
            </div>

            <div class="panel-item">
              <div class="item-label">字体</div>
              <el-select
                v-model="selectedElement.propsValue.font"
                style="width: 100%"
              >
                <el-option
                  v-for="dict in dict.type.font_typeface"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </div>

            <div class="panel-item">
              <div class="item-label">字体大小</div>
              <el-input-number
                v-model="selectedElement.commonStyle.fontSize"
                :min="12"
                :max="100"
                style="width: 100%"
              />
            </div>

            <div class="panel-item">
              <div class="item-label">字体颜色</div>
              <el-color-picker
                v-model="selectedElement.commonStyle.color"
                show-alpha
              />
            </div>

            <div class="panel-item">
              <div class="item-label">字间距</div>
              <el-input-number
                v-model="selectedElement.commonStyle.letterSpacing"
                :min="0"
                :max="20"
                style="width: 100%"
              />
            </div>

            <div class="panel-item">
              <div class="item-label">样式</div>
              <el-checkbox-group v-model="selectedElement.propsValue.fontStyles">
                <el-checkbox label="italic">斜体</el-checkbox>
                <el-checkbox label="bold">粗体</el-checkbox>
                <el-checkbox label="underline">下划线</el-checkbox>
              </el-checkbox-group>
            </div>

            <!-- 位置和尺寸 -->
            <div class="panel-item">
              <div class="item-label">位置</div>
              <div class="position-inputs">
                <el-input-number
                  v-model="selectedElement.commonStyle.left"
                  label="X"
                />
                <el-input-number
                  v-model="selectedElement.commonStyle.top"
                  label="Y"
                />
              </div>
            </div>
            <div class="panel-item">
              <div class="item-label">尺寸</div>
              <div class="size-inputs">
                <el-input-number
                  v-model="selectedElement.commonStyle.width"
                  label="宽"
                />
                <el-input-number
                  v-model="selectedElement.commonStyle.height"
                  label="高"
                />
              </div>
            </div>
          </template>

          <!-- 图片和视频属性编辑 -->
          <template v-if="['file', 'video'].includes(selectedElement.type)">
            <div class="panel-item">
              <div class="item-label">替换{{ selectedElement.type === 'file' ? '图片' : '视频' }}</div>
              <el-button
                size="small"
                @click="selectedElement.type === 'file' ? replaceImage() : replaceVideo()"
              >
                选择{{ selectedElement.type === 'file' ? '图片' : '视频' }}
              </el-button>
            </div>

            <!-- 位置和尺寸 -->
            <div class="panel-item">
              <div class="item-label">位置</div>
              <div class="position-inputs">
                <el-input-number
                  v-model="selectedElement.commonStyle.left"
                  label="X"
                />
                <el-input-number
                  v-model="selectedElement.commonStyle.top"
                  label="Y"
                />
              </div>
            </div>
            <div class="panel-item">
              <div class="item-label">尺寸</div>
              <div class="size-inputs">
                <el-input-number
                  v-model="selectedElement.commonStyle.width"
                  label="宽"
                />
                <el-input-number
                  v-model="selectedElement.commonStyle.height"
                  label="高"
                />
              </div>
            </div>
          </template>

          <!-- 音乐属性编辑 -->
          <template v-if="selectedElement.type === 'audio'">
            <div class="panel-item">
              <div class="item-label">背景音乐</div>
              <div class="music-player">
                <audio
                  v-if="selectedElement.propsValue.audioSrc"
                  :src="selectedElement.propsValue.audioSrc"
                  controls
                  style="width: 100%"
                ></audio>
                <div class="music-actions">
                  <el-button
                    size="small"
                    @click="chooseBgm"
                  >选择音乐</el-button>
                  <el-button
                    size="small"
                    type="danger"
                    @click="deleteBgm"
                  >删除音乐</el-button>
                </div>
              </div>
            </div>
            <div class="panel-item">
              <div class="item-label">音量</div>
              <el-slider
                v-model="selectedElement.propsValue.volume"
                :max="100"
                :min="0"
                show-input
              />
            </div>
          </template>
        </template>

        <div
          v-else
          class="empty-panel"
        >
          <div class="empty-tip">请在画板上选择需要编辑的元素</div>
        </div>
      </div>
    </div>

    <!-- 素材选择抽屉 -->
    <material-drawer
      ref="materialDrawer"
      :visible.sync="materialDrawerVisible"
      @selectionConfirmed="handleMaterialSelected"
    />
  </div>
</template>

<script>
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";
import {
  getVideoTemplateDetail,
  videoTemplateGenerate,
} from "../../api/ai/template";

export default {
  name: "TemplateEditor",
  components: {
    MaterialDrawer,
  },
  dicts: ["font_typeface"],
  data() {
    return {
      pageData: {},
      pageList: [],
      selectedElement: null,
      materialDrawerVisible: false,
      materialType: "", // image/video
      scale: 1,
      originalWidth: 720,
      originalHeight: 1280,
      audioDrawerVisible: false,
      history: [], // 操作历史
      currentHistoryIndex: -1, // 当前历史记录索引
      maxHistoryLength: 20, // 最大历史记录数
      isRestoringState: false, // 是否正在恢复状态
      menus: [
        { type: "text", title: "文字", icon: "el-icon-notebook-1" },
        { type: "image", title: "图片", icon: "el-icon-picture" },
        { type: "video", title: "视频", icon: "el-icon-video-camera" },
        { type: "music", title: "音乐", icon: "el-icon-headset" },
      ],
      currentMenu: "text",
    };
  },

  computed: {
    currentPage() {
      return this.pageList[0] || {};
    },
    currentPageElements() {
      return this.currentPage.cssObj || [];
    },
    scaledWidth() {
      return this.originalWidth;
    },
    scaledHeight() {
      return this.originalHeight;
    },
    canUndo() {
      return this.history.length > 1 && this.currentHistoryIndex > 0;
    },
    canRedo() {
      return (
        this.history.length > 0 &&
        this.currentHistoryIndex < this.history.length - 1
      );
    },
    canvasStyle() {
      return {
        width: this.scaledWidth + "px",
        height: this.scaledHeight + "px",
        backgroundColor: "#000",
        transform: `scale(${this.scale})`,
        // transformOrigin: "top center",
      };
    },
  },

  directives: {
    draggable: {
      bind(el, binding, vnode) {
        el.style.cursor = "move";
        el.onmousedown = function (e) {
          e.stopPropagation();

          const component = vnode.context;
          const element = component.selectedElement;
          if (!element) return;

          const startX = e.clientX;
          const startY = e.clientY;
          const startLeft = parseFloat(element.commonStyle.left) || 0;
          const startTop = parseFloat(element.commonStyle.top) || 0;
          const scale = component.scale;

          // 拖动开始时保存初始状态
          const initialState = JSON.parse(JSON.stringify(component.pageData));
          let hasMoved = false;

          document.onmousemove = function (e) {
            hasMoved = true;
            const deltaX = (e.clientX - startX) / scale;
            const deltaY = (e.clientY - startY) / scale;

            component.$set(
              element.commonStyle,
              "left",
              Math.round(startLeft + deltaX)
            );
            component.$set(
              element.commonStyle,
              "top",
              Math.round(startTop + deltaY)
            );
          };

          document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;

            // 只有在实际发生移动时才保存历史记录
            if (hasMoved) {
              component.saveToHistory();
            }
          };
        };
      },
    },
  },
  created() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getDetail();
    }
  },
  mounted() {
    this.calculateScale();
    window.addEventListener("resize", this.calculateScale);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.calculateScale);
  },

  methods: {
    // 获取模板数据
    getDetail() {
      getVideoTemplateDetail(this.id).then((res) => {
        this.pageList = res.data.pageList;
        console.log("this.pageList", this.pageList);
        // 确保在数据加载完成后再初始化历史记录
        this.$nextTick(() => {
          this.history = [
            {
              pageList: JSON.parse(JSON.stringify(this.pageList)),
            },
          ];
          this.currentHistoryIndex = 0;
        });
      });
    },

    // 获取元素样式
    getElementStyle(element) {
      const style = { ...element.cssObj };
      // const fontStyles = element.propsValue?.fontStyles || [];
      // 转换为CSS样式对象
      return {
        ...element.cssObj,
        position: "absolute",
        width: style.width + "px",
        height: style.height + "px",
        top: style.y + "px",
        left: style.x + "px",
        // transform: `rotate(${style.rotate || 0}deg)`,
        // fontSize: (style.fontSize || 24) + "px",
        // fontWeight: fontStyles.includes("bold") ? "bold" : "normal",
        // fontStyle: fontStyles.includes("italic") ? "italic" : "normal",
        // textDecoration: fontStyles.includes("underline") ? "underline" : "none",
        // lineHeight: style.lineHeight || "1.5",
        // letterSpacing: (style.letterSpacing || 0) + "px",
        // textAlign: style.textAlign || "center",
        // color: style.color || "#ffffff",
        // backgroundColor: style.backgroundColor || "transparent",
        // zIndex: style.zIndex || 1,
        // fontFamily: element.propsValue?.font || "SimSun",
      };
    },

    // 选中元素
    selectElement(element) {
      // 使用 Vue 的响应式更新方法
      this.$set(this, "selectedElement", element);
    },

    // 关闭属性面板
    closePanel() {
      this.selectedElement = null;
    },

    // 替换图片
    replaceImage() {
      this.materialType = "image";
      this.materialDrawerVisible = true;
      this.replacingElement = this.selectedElement;
    },

    // 替换视频
    replaceVideo() {
      this.materialType = "video";
      this.materialDrawerVisible = true;
      this.replacingElement = this.selectedElement;
      this.$nextTick(() => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(6); // 6 表示视频类型
      });
    },

    // 处理素材选择
    handleMaterialSelected(items) {
      if (!items.length) return;

      if (this.materialType === "audio") {
        // 处理音频选择
        const audioElement = {
          type: "audio",
          id: Date.now().toString(),
          commonStyle: {
            position: "absolute",
            width: 0,
            height: 0,
            left: 0,
            top: 0,
          },
          propsValue: {
            audioSrc: items[0].url,
            volume: 100,
          },
        };

        // 如果已经存在音乐元素，先删除
        const musicIndex = this.currentPageElements.findIndex(
          (el) => el.type === "audio"
        );
        if (musicIndex > -1) {
          this.currentPageElements.splice(musicIndex, 1);
        }

        // 添加新的音乐元素
        this.currentPage.cssObj.push(audioElement);
        this.selectElement(audioElement);
        this.saveToHistory();
      } else {
        if (this.replacingElement) {
          // 替换现有元素的素材
          if (this.materialType === "image") {
            this.$set(this.replacingElement.propsValue, "imageSrcList", [
              items[0].url,
            ]);
            this.replacingElement.fromMaterial = true;
          } else if (this.materialType === "video") {
            // 更新视频源信息
            this.$set(this.replacingElement.propsValue, "videoSrc", {
              id: items[0].id,
              url: items[0].url,
            });
            this.replacingElement.fromMaterial = true; // 标记为已替换
          }
          this.replacingElement = null;
        } else {
          // 创建新元素
          if (this.materialType === "image") {
            const element = this.createImageElement(items[0].url);
            element.fromMaterial = true;
            this.addElement(element);
          } else if (this.materialType === "video") {
            const element = this.createVideoElement(items[0]);
            element.fromMaterial = true;
            this.addElement(element);
          }
        }
      }

      this.materialDrawerVisible = false;
      this.saveToHistory();
    },

    // 保存模板
    async saveTemplate() {
      try {
        // 检查视频元素
        const videoElements = this.currentPageElements.filter(
          (el) => el.type === "video"
        );
        const hasOriginalVideo = videoElements.some((el) => !el.fromMaterial);

        if (hasOriginalVideo) {
          this.$modal.msgError("请替换模板中的视频素材后再制作");
          return;
        }

        const convertedData = this.convertTemplateData();
        console.log("转换后的数据:", convertedData);

        // 如果没有视频元素，也提示错误
        if (!convertedData.mediaList.some((item) => item.type === "video")) {
          this.$modal.msgError("请添加视频素材");
          return;
        }

        await videoTemplateGenerate(convertedData);
        this.$modal.msgSuccess("视频任务创建成功");
        this.$tab.closeOpenPage({ path: "/videoManager/videoManager" });
      } catch (error) {
        console.error("保存失败:", error);
        this.$modal.msgError("保存失败: " + error.message);
      }
    },
    calculateScale() {
      const padding = 40; // 上下留出一些空间
      const availableHeight = window.innerHeight - 200; // 减去头部高度和padding
      const scale = (availableHeight - padding) / this.originalHeight;
      this.scale = Math.min(scale, 1); // 确保不会放大，只会缩小
    },

    // 选择背景音乐
    chooseBgm() {
      this.materialType = "audio";
      this.materialDrawerVisible = true;
      this.$nextTick(() => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(2);
      });
    },

    // 添加获取元素类型基础层级的方法
    getBaseZIndex(elementType) {
      switch (elementType) {
        case "video":
        case "file":
          return 10; // 媒体元素基础层级
        case "text":
          return 100; // 文字元素基础层级
        default:
          return 1;
      }
    },

    // 修改获取最大层级的方法，按类型分别计算
    getMaxZIndex(elementType) {
      if (!this.currentPageElements.length) {
        return this.getBaseZIndex(elementType);
      }

      // 根据元素类型获取同类元素的最大 zIndex
      const sameTypeElements = this.currentPageElements.filter((el) => {
        if (elementType === "text") {
          return el.type === "text";
        } else {
          // 图片和视频共享层级范围
          return ["video", "file"].includes(el.type);
        }
      });

      if (!sameTypeElements.length) {
        return this.getBaseZIndex(elementType);
      }

      const maxZIndex = Math.max(
        ...sameTypeElements.map(
          (el) => parseInt(el.commonStyle.zIndex) || this.getBaseZIndex(el.type)
        )
      );

      return maxZIndex + 1;
    },

    // 修改创建视频元素的方法
    createVideoElement(videoData) {
      return {
        type: "video",
        id: Date.now().toString(),
        commonStyle: {
          position: "absolute",
          width: 1080,
          height: 640,
          left: (this.originalWidth - 1080) / 2,
          top: (this.originalHeight - 640) / 2,
          rotate: 0,
          zIndex: this.getMaxZIndex("video"),
        },
        propsValue: {
          videoSrc: {
            id: videoData.id,
            url: videoData.url,
          },
          autoplay: false,
          loop: false,
        },
        animations: [],
      };
    },

    // 修改创建图片元素的方法
    createImageElement(imageUrl) {
      return {
        type: "file",
        id: Date.now().toString(),
        commonStyle: {
          position: "absolute",
          width: 540,
          height: 540,
          left: (this.originalWidth - 540) / 2,
          top: (this.originalHeight - 540) / 2,
          rotate: 0,
          zIndex: this.getMaxZIndex("file"),
        },
        propsValue: {
          imageSrcList: [imageUrl],
          interval: 3,
          fit: "cover",
        },
        animations: [],
      };
    },

    // 添加元素到画布
    addElement(element) {
      if (!this.currentPage.cssObj) {
        this.$set(this.currentPage, "cssObj", {});
      }

      // 使用 Vue 的响应式方法添加元素
      const newElements = [...this.currentPage.cssObj, element];
      this.$set(this.currentPage, "cssObj", newElements);

      // 选中新添加的元素
      this.$nextTick(() => {
        this.selectElement(element);
        this.saveToHistory();
      });
    },

    // 添加新元素的方法
    addElementByType(type) {
      switch (type) {
        case "text":
          const textElement = {
            type: "text",
            id: Date.now().toString(),
            commonStyle: {
              position: "absolute",
              width: 300,
              height: 40,
              left: (this.originalWidth - 300) / 2,
              top: (this.originalHeight - 40) / 2,
              rotate: 0,
              fontSize: 24,
              fontWeight: 400,
              lineHeight: 1.5,
              letterSpacing: 0,
              textAlign: "center",
              color: "#ffffff",
              backgroundColor: "transparent",
              zIndex: this.getMaxZIndex("text"),
            },
            propsValue: {
              text: "请输入文本",
              font: "SimSun",
              fontStyles: [], // 用于存储字体样式：italic, bold, underline
            },
            animations: [],
          };
          this.addElement(textElement);
          break;

        case "image":
          this.materialType = "image";
          this.materialDrawerVisible = true;
          this.$nextTick(() => {
            this.$refs.materialDrawer.$refs.materialLibrary.init(3);
          });
          break;

        case "video":
          this.materialType = "video";
          this.materialDrawerVisible = true;
          this.$nextTick(() => {
            this.$refs.materialDrawer.$refs.materialLibrary.init(6);
          });
          break;
      }
    },

    getDefaultPropsValue(type) {
      switch (type) {
        case "text":
          return { text: "请输入文本", font: "SimSun" };
        case "qk-subtitle":
          return { content: "请输入字幕", position: "bottom" };
        case "audio":
          return { audioSrc: "", audioName: "", volume: 100 };
        default:
          return {};
      }
    },

    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0;
          const v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },

    // 删除元素
    deleteElement(element) {
      if (!element || !element.id) return;

      const index = this.currentPageElements.findIndex(
        (el) => el.id === element.id
      );
      if (index === -1) return;

      // 先保存当前状态到历史记录
      this.saveToHistory();

      // 修改数据
      const newElements = [
        ...this.currentPageElements.slice(0, index),
        ...this.currentPageElements.slice(index + 1),
      ];
      this.$set(this.currentPage, "cssObj", newElements);

      if (this.selectedElement === element) {
        this.$set(this, "selectedElement", null);
      }

      // 保存删除后的状态
      this.$nextTick(() => {
        this.saveToHistory();
      });
    },

    // 保存当前状态到历史记录
    saveToHistory() {
      if (this.isRestoringState) return;

      const currentState = {
        pageList: JSON.parse(JSON.stringify(this.pageList)),
        selectedUuid: this.selectedElement?.id,
      };

      // 删除当前位置之后的历史记录
      this.history = this.history.slice(0, this.currentHistoryIndex + 1);
      this.history.push(currentState);
      this.currentHistoryIndex = this.history.length - 1;

      // 如果历史记录超过最大长度，删除最早的记录
      if (this.history.length > this.maxHistoryLength) {
        this.history.shift();
        this.currentHistoryIndex = Math.max(0, this.currentHistoryIndex - 1);
      }
    },

    // 添加用于比较的数据提取方法
    getCompareData(elements) {
      return elements.map((el) => ({
        id: el.id,
        commonStyle: {
          left: el.commonStyle.left,
          top: el.commonStyle.top,
          width: el.commonStyle.width,
          height: el.commonStyle.height,
          rotate: el.commonStyle.rotate,
        },
        propsValue: el.propsValue,
      }));
    },

    // 撤销
    undo() {
      if (!this.canUndo) return;
      this.currentHistoryIndex--;
      this.restoreState(this.history[this.currentHistoryIndex]);
    },

    // 恢复
    redo() {
      if (!this.canRedo) return;
      this.currentHistoryIndex++;
      this.restoreState(this.history[this.currentHistoryIndex]);
    },

    // 恢复到指定状态
    restoreState(state) {
      if (!state || !state.pageList) return;

      this.isRestoringState = true;

      // 更新整个 pageList
      this.$set(this, "pageList", JSON.parse(JSON.stringify(state.pageList)));

      // 恢复选中状态
      if (state.selectedUuid) {
        const newElement = this.currentPageElements.find(
          (el) => el.id === state.selectedUuid
        );
        this.$set(this, "selectedElement", newElement || null);
      } else {
        this.$set(this, "selectedElement", null);
      }

      // 确保状态完全更新后再重置标记
      this.$nextTick(() => {
        this.isRestoringState = false;
      });
    },

    // 转换元素数据为新格式
    convertElementToNewFormat(element) {
      const commonStyle = element.commonStyle;
      const base = {
        width: commonStyle.width / this.originalWidth,
        height: commonStyle.height / this.originalHeight,
        x: commonStyle.left / this.originalWidth,
        y: commonStyle.top / this.originalHeight,
        adaptMode: "Cover",
      };

      switch (element.type) {
        case "text":
          return {
            content: element.propsValue.text,
            fontColor: commonStyle.color,
            fontSize: commonStyle.fontSize,
            alignment: this.getAlignment(commonStyle),
            sizeRequestType: "Nominal",
            font: element.propsValue.font || "SimSun",
            x: base.x,
            y: base.y,
          };

        case "file":
          return {
            mediaUrls: element.propsValue.imageSrcList.map((url) => ({ url })),
            // 如果是从素材库选择的图片，使用 fromMaterial 标记
            type: element.fromMaterial ? "image" : "stickers",
            ...base,
          };

        case "video":
          return {
            mediaUrls: [
              {
                id: element.propsValue.videoSrc.id,
                url: element.propsValue.videoSrc.url,
              },
            ],
            type: "video",
            ...base,
          };

        default:
          return null;
      }
    },

    // 获取对齐方式
    getAlignment(style) {
      // 根据位置计算对齐方式
      const y = style.top / this.originalHeight;
      if (y < 0.3) return "TopCenter";
      if (y > 0.7) return "BottomCenter";
      return "CenterCenter";
    },

    // 转换整个模板数据
    convertTemplateData() {
      const elements = this.currentPageElements;
      const titles = [];
      const mediaList = [];
      let music = null;

      elements.forEach((element) => {
        const converted = this.convertElementToNewFormat(element);
        if (!converted) return;

        if (element.type === "text") {
          titles.push(converted);
        } else if (element.type === "audio") {
          if (element.propsValue.audioSrc) {
            // 只有在有音频源时才设置 music
            music = {
              url: element.propsValue.audioSrc,
              volume: element.propsValue.volume / 100,
              speed: 1,
            };
          }
        } else {
          mediaList.push(converted);
        }
      });

      // 构建返回数据
      const result = {
        templateId: this.id,
        titles,
        mediaList,
      };

      // 只有当 music 有值时才添加到结果中
      if (music && music.url) {
        result.music = music;
      } else {
        result.music = null;
      }

      return result;
    },

    // 选择菜单
    selectMenu(type) {
      this.currentMenu = type;

      // 如果是音乐菜单，不自动打开选择器
      if (type === "music") {
        const musicElement = this.currentPageElements.find(
          (el) => el.type === "audio"
        );
        if (musicElement) {
          this.selectElement(musicElement);
        } else {
          this.selectedElement = null; // 清空选中状态，显示空面板
        }
        return;
      }

      // 其他类型元素的处理
      const firstElement = this.currentPageElements.find((el) => {
        switch (type) {
          case "text":
            return el.type === "text";
          case "image":
            return el.type === "file";
          case "video":
            return el.type === "video";
        }
      });
      if (firstElement) {
        this.selectElement(firstElement);
      }
    },

    getMenuTitle(type) {
      const menu = this.menus.find((m) => m.type === type);
      return menu ? menu.title : "";
    },

    // 删除背景音乐
    deleteBgm() {
      const index = this.currentPageElements.findIndex(
        (el) => el.type === "audio"
      );
      if (index > -1) {
        // 先保存历史记录
        this.saveToHistory();

        // 删除音乐元素
        const newElements = [
          ...this.currentPageElements.slice(0, index),
          ...this.currentPageElements.slice(index + 1),
        ];
        this.$set(this.currentPage, "cssObj", newElements);

        // 清空选中状态
        this.selectedElement = null;

        // 保存删除后的状态
        this.$nextTick(() => {
          this.saveToHistory();
        });
      }
    },
  },

  watch: {
    "selectedElement.propsValue": {
      handler(newVal) {
        if (newVal) {
          this.$forceUpdate();
          this.saveToHistory();
        }
      },
      deep: true,
    },
    "selectedElement.commonStyle": {
      handler(newVal) {
        if (newVal) {
          this.$forceUpdate();
          this.saveToHistory();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.template-editor {
  padding: 16px;
  overflow: hidden;
  .editor-content {
    height: calc(100vh - 200px);
    display: flex;
    padding: 20px;
    overflow: hidden;
    // background: white;
    background: linear-gradient(
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    border-radius: 14px;
    border: 1px solid #ffffff;

    .editor-canvas-wrapper {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      // min-width: 0;
      // flex: 1;
    }

    .editor-canvas {
      position: relative;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transform-origin: top center;
    }

    .element {
      position: absolute;
      cursor: move;

      &:hover {
        outline: 1px solid #409eff;
      }

      &.text-element {
        user-select: none;
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .editor-panel {
      width: 300px;
      margin-left: 20px;
      border-left: 1px solid #eee;
      padding: 20px;
      overflow: auto;

      .panel-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }

      .panel-item {
        margin-bottom: 15px;

        .item-label {
          margin-bottom: 5px;
          color: #666;
        }

        .position-inputs,
        .size-inputs {
          display: flex;
          gap: 10px;
        }
      }

      .shadow-settings {
        margin-top: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .audio-info {
        margin-top: 10px;
        padding: 10px;
        background: #f5f7fa;
        border-radius: 4px;

        span {
          display: block;
          margin-bottom: 5px;
          font-size: 12px;
          color: #666;
        }

        audio {
          width: 100%;
          height: 30px;
        }
      }
    }
  }
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: #909399;
  z-index: 10;
  padding: 4px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.element:hover .delete-icon {
  opacity: 1;
}

.delete-icon:hover {
  color: #f56c6c;
  background: rgba(255, 255, 255, 0.9);
}

.relative {
  position: relative;
}

.text-element .delete-icon {
  transform: scale(1);
  top: 4px;
  right: 4px;
}

.image-element .delete-icon,
.video-element .delete-icon {
  transform: scale(1);
  top: 8px;
  right: 8px;
}

.editor-menu {
  width: 120px;
  background: #f5f5f5;
  padding: 20px 10px;
  border-radius: 7px;
  overflow: auto;

  .menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 8px;
    transition: all 0.3s;

    &:hover {
      background: #e6e6e6;
    }

    &.active {
      background: #e6f1fc;
      color: #409eff;
    }

    i {
      font-size: 18px;
    }
  }

  .add-button {
    width: 100%;
    margin-top: 12px;
  }
}

.editor-main {
  flex: 1;
  min-width: 0;
  // overflow: auto;
}

.editor-panel {
  width: 300px;
  background: #fff;
  border-left: 1px solid #eee;

  .empty-panel {
    padding: 20px;
    text-align: center;
    color: #909399;
  }
}

.element {
  &.active {
    outline: 2px solid #409eff;
  }
}
</style>
