<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="主键" prop="id">
        <el-input
          v-model="queryParams.id"
          placeholder="请输入主键"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="创建者" prop="createId">
        <el-input
          v-model="queryParams.createId"
          placeholder="请输入创建者"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="更新者" prop="updateId">
        <el-input
          v-model="queryParams.updateId"
          placeholder="请输入更新者"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="是否订阅" prop="subscribe">
        <el-input
          v-model="queryParams.subscribe"
          placeholder="请输入是否订阅"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="返回用户关注的渠道来源，ADD_SCENE_SEARCH 公众号搜索，ADD_SCENE_ACCOUNT_MIGRATION 公众号迁移，ADD_SCENE_PROFILE_CARD 名片分享，ADD_SCENE_QR_CODE 扫描二维码，ADD_SCENEPROFILE LINK 图文页内名称点击，ADD_SCENE_PROFILE_ITEM 图文页右上角菜单，ADD_SCENE_PAID 支付后关注，ADD_SCENE_OTHERS 其他" prop="subscribeScene">
        <el-input
          v-model="queryParams.subscribeScene"
          placeholder="请输入返回用户关注的渠道来源，ADD_SCENE_SEARCH 公众号搜索，ADD_SCENE_ACCOUNT_MIGRATION 公众号迁移，ADD_SCENE_PROFILE_CARD 名片分享，ADD_SCENE_QR_CODE 扫描二维码，ADD_SCENEPROFILE LINK 图文页内名称点击，ADD_SCENE_PROFILE_ITEM 图文页右上角菜单，ADD_SCENE_PAID 支付后关注，ADD_SCENE_OTHERS 其他"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="关注时间" prop="subscribeTime">
        <el-date-picker clearable
          v-model="queryParams.subscribeTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择关注时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="关注次数" prop="subscribeNum">
        <el-input
          v-model="queryParams.subscribeNum"
          placeholder="请输入关注次数"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="取消关注时间" prop="cancelSubscribeTime">
        <el-date-picker clearable
          v-model="queryParams.cancelSubscribeTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择取消关注时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="用户标识" prop="openId">
        <el-input
          v-model="queryParams.openId"
          placeholder="请输入用户标识"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="昵称" prop="nickName">
        <el-input
          v-model="queryParams.nickName"
          placeholder="请输入昵称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="所在城市" prop="city">
        <el-input
          v-model="queryParams.city"
          placeholder="请输入所在城市"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="所在国家" prop="country">
        <el-input
          v-model="queryParams.country"
          placeholder="请输入所在国家"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="所在省份" prop="province">
        <el-input
          v-model="queryParams.province"
          placeholder="请输入所在省份"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input
          v-model="queryParams.phone"
          placeholder="请输入手机号码"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="用户语言" prop="language">
        <el-input
          v-model="queryParams.language"
          placeholder="请输入用户语言"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="头像" prop="headimgUrl">
        <el-input
          v-model="queryParams.headimgUrl"
          placeholder="请输入头像"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="union_id" prop="unionId">
        <el-input
          v-model="queryParams.unionId"
          placeholder="请输入union_id"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="用户组" prop="groupId">
        <el-input
          v-model="queryParams.groupId"
          placeholder="请输入用户组"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="标签列表" prop="tagidList">
        <el-input
          v-model="queryParams.tagidList"
          placeholder="请输入标签列表"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="二维码扫码场景" prop="qrSceneStr">
        <el-input
          v-model="queryParams.qrSceneStr"
          placeholder="请输入二维码扫码场景"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="地理位置纬度" prop="latitude">
        <el-input
          v-model="queryParams.latitude"
          placeholder="请输入地理位置纬度"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="地理位置经度" prop="longitude">
        <el-input
          v-model="queryParams.longitude"
          placeholder="请输入地理位置经度"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="地理位置精度" prop="precision">
        <el-input
          v-model="queryParams.precision"
          placeholder="请输入地理位置精度"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="会话密钥" prop="sessionKey">
        <el-input
          v-model="queryParams.sessionKey"
          placeholder="请输入会话密钥"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{$t('search')}}</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">{{$t('reset')}}</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['system:wxUser:add']"
        >{{ $t('add') }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="iconfont icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:wxUser:edit']"
        >{{ $t('update') }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="iconfont icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:wxUser:remove']"
        >{{$t('remove')}}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="iconfont icon-daoru"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:wxUser:export']"
        >{{ $t('export') }}</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="wxUserList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" v-if="true"/>
      <el-table-column label="创建者" align="center" prop="createId" />
      <el-table-column label="更新者" align="center" prop="updateId" />
      <el-table-column label="用户备注" align="center" prop="remark" />
      <el-table-column label="应用类型(1:小程序，2:公众号)" align="center" prop="appType" />
      <el-table-column label="是否订阅" align="center" prop="subscribe" />
      <el-table-column label="返回用户关注的渠道来源，ADD_SCENE_SEARCH 公众号搜索，ADD_SCENE_ACCOUNT_MIGRATION 公众号迁移，ADD_SCENE_PROFILE_CARD 名片分享，ADD_SCENE_QR_CODE 扫描二维码，ADD_SCENEPROFILE LINK 图文页内名称点击，ADD_SCENE_PROFILE_ITEM 图文页右上角菜单，ADD_SCENE_PAID 支付后关注，ADD_SCENE_OTHERS 其他" align="center" prop="subscribeScene" />
      <el-table-column label="关注时间" align="center" prop="subscribeTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.subscribeTime, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关注次数" align="center" prop="subscribeNum" />
      <el-table-column label="取消关注时间" align="center" prop="cancelSubscribeTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.cancelSubscribeTime, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户标识" align="center" prop="openId" />
      <el-table-column label="昵称" align="center" prop="nickName" />
      <el-table-column label="性别" align="center" prop="sex" />
      <el-table-column label="所在城市" align="center" prop="city" />
      <el-table-column label="所在国家" align="center" prop="country" />
      <el-table-column label="所在省份" align="center" prop="province" />
      <el-table-column label="手机号码" align="center" prop="phone" />
      <el-table-column label="用户语言" align="center" prop="language" />
      <el-table-column label="头像" align="center" prop="headimgUrl" />
      <el-table-column label="union_id" align="center" prop="unionId" />
      <el-table-column label="用户组" align="center" prop="groupId" />
      <el-table-column label="标签列表" align="center" prop="tagidList" />
      <el-table-column label="二维码扫码场景" align="center" prop="qrSceneStr" />
      <el-table-column label="地理位置纬度" align="center" prop="latitude" />
      <el-table-column label="地理位置经度" align="center" prop="longitude" />
      <el-table-column label="地理位置精度" align="center" prop="precision" />
      <el-table-column label="会话密钥" align="center" prop="sessionKey" />
      <el-table-column :label="$t('operate')" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="iconfont icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:wxUser:edit']"
          >{{ $t('update') }}</el-button>
          <el-button
            size="mini"
            type="text"
            icon="iconfont icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:wxUser:remove']"
          >{{$t('remove')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改微信小程序用户对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="创建者" prop="createId">
          <el-input v-model="form.createId" maxlength="50" placeholder="请输入创建者" />
        </el-form-item>
        <el-form-item label="更新者" prop="updateId">
          <el-input v-model="form.updateId" maxlength="50" placeholder="请输入更新者" />
        </el-form-item>
        <el-form-item label="用户备注" prop="remark">
          <el-input v-model="form.remark" maxlength="200" placeholder="请输入用户备注" />
        </el-form-item>
        <el-form-item label="是否订阅" prop="subscribe">
          <el-input v-model="form.subscribe" maxlength="50" placeholder="请输入是否订阅" />
        </el-form-item>
        <el-form-item label="返回用户关注的渠道来源，ADD_SCENE_SEARCH 公众号搜索，ADD_SCENE_ACCOUNT_MIGRATION 公众号迁移，ADD_SCENE_PROFILE_CARD 名片分享，ADD_SCENE_QR_CODE 扫描二维码，ADD_SCENEPROFILE LINK 图文页内名称点击，ADD_SCENE_PROFILE_ITEM 图文页右上角菜单，ADD_SCENE_PAID 支付后关注，ADD_SCENE_OTHERS 其他" prop="subscribeScene">
          <el-input v-model="form.subscribeScene" maxlength="200" placeholder="请输入返回用户关注的渠道来源，ADD_SCENE_SEARCH 公众号搜索，ADD_SCENE_ACCOUNT_MIGRATION 公众号迁移，ADD_SCENE_PROFILE_CARD 名片分享，ADD_SCENE_QR_CODE 扫描二维码，ADD_SCENEPROFILE LINK 图文页内名称点击，ADD_SCENE_PROFILE_ITEM 图文页右上角菜单，ADD_SCENE_PAID 支付后关注，ADD_SCENE_OTHERS 其他" />
        </el-form-item>
        <el-form-item label="关注时间" prop="subscribeTime">
          <el-date-picker clearable
            v-model="form.subscribeTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择关注时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关注次数" prop="subscribeNum">
          <el-input v-model="form.subscribeNum" maxlength="50" placeholder="请输入关注次数" />
        </el-form-item>
        <el-form-item label="取消关注时间" prop="cancelSubscribeTime">
          <el-date-picker clearable
            v-model="form.cancelSubscribeTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择取消关注时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用户标识" prop="openId">
          <el-input v-model="form.openId" maxlength="50" placeholder="请输入用户标识" />
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="form.nickName" maxlength="50" placeholder="请输入昵称" />
        </el-form-item>
        <el-form-item label="所在城市" prop="city">
          <el-input v-model="form.city" maxlength="50" placeholder="请输入所在城市" />
        </el-form-item>
        <el-form-item label="所在国家" prop="country">
          <el-input v-model="form.country" maxlength="50" placeholder="请输入所在国家" />
        </el-form-item>
        <el-form-item label="所在省份" prop="province">
          <el-input v-model="form.province" maxlength="50" placeholder="请输入所在省份" />
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号码" />
        </el-form-item>
        <el-form-item label="用户语言" prop="language">
          <el-input v-model="form.language" maxlength="50" placeholder="请输入用户语言" />
        </el-form-item>
        <el-form-item label="头像" prop="headimgUrl">
          <el-input v-model="form.headimgUrl" maxlength="50" type="textarea" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="union_id" prop="unionId">
          <el-input v-model="form.unionId" maxlength="200" placeholder="请输入union_id" />
        </el-form-item>
        <el-form-item label="用户组" prop="groupId">
          <el-input v-model="form.groupId" maxlength="50" placeholder="请输入用户组" />
        </el-form-item>
        <el-form-item label="标签列表" prop="tagidList">
          <el-input v-model="form.tagidList" maxlength="50" placeholder="请输入标签列表" />
        </el-form-item>
        <el-form-item label="二维码扫码场景" prop="qrSceneStr">
          <el-input v-model="form.qrSceneStr" maxlength="200" placeholder="请输入二维码扫码场景" />
        </el-form-item>
        <el-form-item label="地理位置纬度" prop="latitude">
          <el-input v-model="form.latitude" maxlength="50" placeholder="请输入地理位置纬度" />
        </el-form-item>
        <el-form-item label="地理位置经度" maxlength="50" prop="longitude">
          <el-input v-model="form.longitude" placeholder="请输入地理位置经度" />
        </el-form-item>
        <el-form-item label="地理位置精度" prop="precision">
          <el-input v-model="form.precision" maxlength="50" placeholder="请输入地理位置精度" />
        </el-form-item>
        <el-form-item label="会话密钥" prop="sessionKey">
          <el-input v-model="form.sessionKey" maxlength="200" placeholder="请输入会话密钥" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonLoading" type="primary" @click="submitForm">{{ $t('confirm') }}</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listWxUser, getWxUser, delWxUser, addWxUser, updateWxUser } from "@/api/system/wxUser";

export default {
  name: "WxUser",
  data() {
    return {
      // 按钮loading
      buttonLoading: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 微信小程序用户表格数据
      wxUserList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: undefined,
        createId: undefined,
        updateId: undefined,
        appType: undefined,
        subscribe: undefined,
        subscribeScene: undefined,
        subscribeTime: undefined,
        subscribeNum: undefined,
        cancelSubscribeTime: undefined,
        openId: undefined,
        nickName: undefined,
        sex: undefined,
        city: undefined,
        country: undefined,
        province: undefined,
        phone: undefined,
        language: undefined,
        headimgUrl: undefined,
        unionId: undefined,
        groupId: undefined,
        tagidList: undefined,
        qrSceneStr: undefined,
        latitude: undefined,
        longitude: undefined,
        precision: undefined,
        sessionKey: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        id: [
          { required: true, message: "主键不能为空", trigger: "blur" }
        ],
        createId: [
          { required: true, message: "创建者不能为空", trigger: "blur" }
        ],
        updateId: [
          { required: true, message: "更新者不能为空", trigger: "blur" }
        ],
        remark: [
          { required: true, message: "用户备注不能为空", trigger: "blur" }
        ],
        appType: [
          { required: true, message: "应用类型(1:小程序，2:公众号)不能为空", trigger: "change" }
        ],
        subscribe: [
          { required: true, message: "是否订阅不能为空", trigger: "blur" }
        ],
        subscribeScene: [
          { required: true, message: "返回用户关注的渠道来源，ADD_SCENE_SEARCH 公众号搜索，ADD_SCENE_ACCOUNT_MIGRATION 公众号迁移，ADD_SCENE_PROFILE_CARD 名片分享，ADD_SCENE_QR_CODE 扫描二维码，ADD_SCENEPROFILE LINK 图文页内名称点击，ADD_SCENE_PROFILE_ITEM 图文页右上角菜单，ADD_SCENE_PAID 支付后关注，ADD_SCENE_OTHERS 其他不能为空", trigger: "blur" }
        ],
        subscribeTime: [
          { required: true, message: "关注时间不能为空", trigger: "blur" }
        ],
        subscribeNum: [
          { required: true, message: "关注次数不能为空", trigger: "blur" }
        ],
        cancelSubscribeTime: [
          { required: true, message: "取消关注时间不能为空", trigger: "blur" }
        ],
        openId: [
          { required: true, message: "用户标识不能为空", trigger: "blur" }
        ],
        nickName: [
          { required: true, message: "昵称不能为空", trigger: "blur" }
        ],
        sex: [
          { required: true, message: "性别不能为空", trigger: "change" }
        ],
        city: [
          { required: true, message: "所在城市不能为空", trigger: "blur" }
        ],
        country: [
          { required: true, message: "所在国家不能为空", trigger: "blur" }
        ],
        province: [
          { required: true, message: "所在省份不能为空", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" }
        ],
        language: [
          { required: true, message: "用户语言不能为空", trigger: "blur" }
        ],
        headimgUrl: [
          { required: true, message: "头像不能为空", trigger: "blur" }
        ],
        unionId: [
          { required: true, message: "union_id不能为空", trigger: "blur" }
        ],
        groupId: [
          { required: true, message: "用户组不能为空", trigger: "blur" }
        ],
        tagidList: [
          { required: true, message: "标签列表不能为空", trigger: "blur" }
        ],
        qrSceneStr: [
          { required: true, message: "二维码扫码场景不能为空", trigger: "blur" }
        ],
        latitude: [
          { required: true, message: "地理位置纬度不能为空", trigger: "blur" }
        ],
        longitude: [
          { required: true, message: "地理位置经度不能为空", trigger: "blur" }
        ],
        precision: [
          { required: true, message: "地理位置精度不能为空", trigger: "blur" }
        ],
        sessionKey: [
          { required: true, message: "会话密钥不能为空", trigger: "blur" }
        ],
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询微信小程序用户列表 */
    getList() {
      this.loading = true;
      listWxUser(this.queryParams).then(response => {
        this.wxUserList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        createId: undefined,
        createTime: undefined,
        updateId: undefined,
        updateTime: undefined,
        remark: undefined,
        delFlag: undefined,
        appType: undefined,
        subscribe: undefined,
        subscribeScene: undefined,
        subscribeTime: undefined,
        subscribeNum: undefined,
        cancelSubscribeTime: undefined,
        openId: undefined,
        nickName: undefined,
        sex: undefined,
        city: undefined,
        country: undefined,
        province: undefined,
        phone: undefined,
        language: undefined,
        headimgUrl: undefined,
        unionId: undefined,
        groupId: undefined,
        tagidList: undefined,
        qrSceneStr: undefined,
        latitude: undefined,
        longitude: undefined,
        precision: undefined,
        sessionKey: undefined,
        createBy: undefined,
        updateBy: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加微信小程序用户";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.loading = true;
      this.reset();
      const id = row.id || this.ids
      getWxUser(id).then(response => {
        this.loading = false;
        this.form = response.data;
        this.open = true;
        this.title = "修改微信小程序用户";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.buttonLoading = true;
          if (this.form.id != null) {
            updateWxUser(this.form).then(response => {
              this.$modal.msgSuccess(this.$t('tip.update'));
              this.open = false;
              this.getList();
            }).finally(() => {
              this.buttonLoading = false;
            });
          } else {
            addWxUser(this.form).then(response => {
              this.$modal.msgSuccess(this.$t('tip.add'));
              this.open = false;
              this.getList();
            }).finally(() => {
              this.buttonLoading = false;
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认删除微信小程序用户编号为"' + ids + '"的数据项？').then(() => {
        this.loading = true;
        return delWxUser(ids);
      }).then(() => {
        this.loading = false;
        this.getList();
        this.$modal.msgSuccess(this.$t('tip.remove'));
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      let params = this.queryParams;
      params.ids = this.ids;
      this.download('system/wxUser/export', {
        ...params
      }, `wxUser_${new Date().getTime()}.xlsx`)
    }
  }
};
</script>
