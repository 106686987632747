var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "editor-container",
    },
    [
      _c(
        "div",
        { staticClass: "editor-header" },
        [
          _vm._m(0),
          _c(
            "el-button",
            {
              staticClass: "gradient-button",
              attrs: { type: "primary" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("生成视频")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "editor-main" }, [
        _c(
          "div",
          { staticClass: "editor-tools" },
          _vm._l(_vm.tools, function (tool) {
            return _c(
              "div",
              {
                key: tool.type,
                staticClass: "tool-item",
                class: { active: _vm.currentTool === tool.type },
                on: {
                  click: function ($event) {
                    return _vm.selectTool(tool.type)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "iconfont iconfont-tool",
                  class: tool.icon,
                }),
                _c("span", [_vm._v(_vm._s(tool.name))]),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "editor-canvas" }, [
          _c(
            "div",
            { staticClass: "canvas-container", style: _vm.canvasStyle },
            _vm._l(_vm.pageData.pageList, function (element, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "draggable",
                      rawName: "v-draggable",
                      value: element,
                      expression: "element",
                    },
                  ],
                  key: element.id || index,
                  staticClass: "element",
                  class: [
                    element.type === "image" ||
                    element.type === "file" ||
                    element.type === "video"
                      ? "media-element"
                      : "text-element",
                    {
                      active:
                        _vm.selectedElement &&
                        _vm.selectedElement.id === element.id,
                    },
                  ],
                  style: _vm.getElementStyle(element),
                  on: {
                    click: function ($event) {
                      return _vm.selectElement(element)
                    },
                  },
                },
                [
                  element.type === "image" || element.type === "file"
                    ? [
                        _c("img", {
                          staticStyle: {
                            width: "100%",
                            height: "100%",
                            "object-fit": "cover",
                          },
                          attrs: { src: element.url },
                        }),
                      ]
                    : element.type === "video"
                    ? [
                        _vm.getTypeByUrl(element.url) === "video"
                          ? _c("video", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                "object-fit": "cover",
                              },
                              attrs: { src: element.url, controls: "" },
                            })
                          : _c("img", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                "object-fit": "cover",
                              },
                              attrs: { src: element.url },
                            }),
                      ]
                    : element.type === "text"
                    ? [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formattedContent(element.content) || ""
                            ),
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "editor-canvas-settings flex-column-center" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "settings-item",
                  attrs: {
                    effect: "dark",
                    content: "复制",
                    placement: "right",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-fuzhi",
                    class: { disabled: !_vm.selectedElement },
                    on: { click: _vm.copyElement },
                  }),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "settings-item",
                  attrs: {
                    effect: "dark",
                    content: "删除",
                    placement: "right",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-delete",
                    class: { disabled: !_vm.selectedElement },
                    on: { click: _vm.deleteElement },
                  }),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "settings-item",
                  attrs: {
                    effect: "dark",
                    content: "字体放大",
                    placement: "right",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-zitifangda",
                    class: { disabled: !_vm.canEditFont },
                    on: { click: _vm.enlargementFont },
                  }),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "settings-item",
                  attrs: {
                    effect: "dark",
                    content: "字体缩小",
                    placement: "right",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-zitisuoxiao",
                    class: { disabled: !_vm.canEditFont },
                    on: { click: _vm.reductionFont },
                  }),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "settings-item",
                  attrs: {
                    effect: "dark",
                    content: "撤销",
                    placement: "right",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-chexiao",
                    class: { disabled: !_vm.canUndo },
                    on: { click: _vm.undo },
                  }),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "settings-item",
                  attrs: {
                    effect: "dark",
                    content: "恢复",
                    placement: "right",
                  },
                },
                [
                  _c("i", {
                    staticClass: "iconfont icon-huifu",
                    class: { disabled: !_vm.canRedo },
                    on: { click: _vm.redo },
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "editor-panel" },
          [
            _vm.selectedElement
              ? [
                  _vm.selectedElement.type === "text"
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "right-title" }, [
                            _vm._v("文本内容："),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingTranslate,
                                  expression: "loadingTranslate",
                                },
                              ],
                              staticClass: "textInput",
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "500",
                                  type: "textarea",
                                  autosize: { minRows: 4, maxRows: 6 },
                                  placeholder: _vm.$t("tip.input"),
                                },
                                on: { change: _vm.addHistory },
                                model: {
                                  value: _vm.selectedElement.content,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement,
                                      "content",
                                      $$v
                                    )
                                  },
                                  expression: "selectedElement.content",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "flexInputBtn f-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex-row-center" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btnBox",
                                          on: { click: _vm.chooseLanguage },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-danyuanpeizhi",
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("issueItem.translate")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btnBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.rewrite("sc")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-zongjie",
                                          }),
                                          _vm._v(
                                            _vm._s(_vm.$t("subtitle.sumup"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btnBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.rewrite("sx")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-a-danyuanpeizhi4",
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("subtitle.abbreviation")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btnBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.rewrite("kx")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-a-danyuanpeizhi5",
                                          }),
                                          _vm._v(
                                            _vm._s(_vm.$t("subtitle.expand"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btnBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.rewrite("cx")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-a-danyuanpeizhi6",
                                          }),
                                          _vm._v(
                                            _vm._s(_vm.$t("subtitle.rewrite"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "iconfont icon-delete pointer",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                "label-position": "right",
                                "label-width": "80px",
                              },
                            },
                            [
                              _c(
                                "el-collapse",
                                {
                                  model: {
                                    value: _vm.activeNames,
                                    callback: function ($$v) {
                                      _vm.activeNames = $$v
                                    },
                                    expression: "activeNames",
                                  },
                                },
                                [
                                  _c(
                                    "el-collapse-item",
                                    { attrs: { title: "字体", name: "1" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "字体名称" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                "default-first-option": true,
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedElement.cssObj[
                                                    "font-family"
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedElement.cssObj,
                                                    "font-family",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedElement.cssObj['font-family']",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dict.type.font_typeface,
                                              function (dict) {
                                                return _c("el-option", {
                                                  key: dict.value,
                                                  attrs: {
                                                    label: dict.label,
                                                    value: dict.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "字体大小" } },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              min: 12,
                                              max: 200,
                                              step: 1,
                                            },
                                            model: {
                                              value:
                                                _vm.selectedElement.cssObj[
                                                  "font-size"
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedElement.cssObj,
                                                  "font-size",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedElement.cssObj['font-size']",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "字体颜色" } },
                                        [
                                          _c("el-color-picker", {
                                            attrs: { "show-alpha": "" },
                                            model: {
                                              value:
                                                _vm.selectedElement.cssObj
                                                  .color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedElement.cssObj,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedElement.cssObj.color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "字间距" } },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: { min: 0, max: 50, step: 1 },
                                            model: {
                                              value:
                                                _vm.selectedElement.cssObj[
                                                  "letter-spacing"
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.selectedElement.cssObj,
                                                  "letter-spacing",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedElement.cssObj['letter-spacing']",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "对齐方式" } },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                input:
                                                  _vm.updateElementAlignment,
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedElement.cssObj[
                                                    "text-align"
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedElement.cssObj,
                                                    "text-align",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedElement.cssObj['text-align']",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "left" } },
                                                [_vm._v("左对齐")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "center" } },
                                                [_vm._v("居中")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "right" } },
                                                [_vm._v("右对齐")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "字体粗细" } },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value:
                                                  _vm.selectedElement.cssObj[
                                                    "font-weight"
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedElement.cssObj,
                                                    "font-weight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedElement.cssObj['font-weight']",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "normal" } },
                                                [_vm._v("正常")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "bold" } },
                                                [_vm._v("粗体")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.selectedElement.type === "image" ||
                      _vm.selectedElement.type === "file" ||
                      _vm.selectedElement.type === "video"
                    ? _c("div", [
                        _c("div", { staticClass: "right-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedElement.type === "video"
                                  ? "视频"
                                  : "图片"
                              ) +
                              "： "
                          ),
                        ]),
                        !_vm.selectedElement.url
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "video-empty flex-column-center f-center",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReplace(
                                      _vm.selectedElement
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-shangchuan",
                                }),
                                _c("span", [
                                  _vm._v(
                                    "添加" +
                                      _vm._s(
                                        _vm.selectedElement.type === "video"
                                          ? "视频"
                                          : "图片"
                                      )
                                  ),
                                ]),
                              ]
                            )
                          : _c("div", { staticClass: "material-container" }, [
                              _vm.getTypeByUrl(_vm.selectedElement.url) ===
                              "video"
                                ? _c("video", {
                                    staticClass: "video-preview",
                                    attrs: {
                                      src: _vm.selectedElement.url,
                                      controls: "",
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "video-preview",
                                    attrs: { src: _vm.selectedElement.url },
                                  }),
                              _c(
                                "div",
                                {
                                  staticClass: "deleteIcon",
                                  on: { click: _vm.removeMaterial },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-delete",
                                  }),
                                ]
                              ),
                            ]),
                        _c("div", { staticClass: "right-title mt32" }, [
                          _vm._v("样式："),
                        ]),
                        _c("div", { staticClass: "panel-item ml20" }, [
                          _c("div", { staticClass: "item-label" }, [
                            _vm._v("位置："),
                          ]),
                          _c(
                            "div",
                            { staticClass: "position-inputs" },
                            [
                              _c(
                                "el-input-number",
                                {
                                  attrs: {
                                    min: 0,
                                    max: _vm.originalWidth,
                                    step: 1,
                                    size: "small",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateElementPosition("x")
                                    },
                                  },
                                  model: {
                                    value: _vm.elementX,
                                    callback: function ($$v) {
                                      _vm.elementX = $$v
                                    },
                                    expression: "elementX",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("X"),
                                  ]),
                                ],
                                2
                              ),
                              _c(
                                "el-input-number",
                                {
                                  attrs: {
                                    min: 0,
                                    max: _vm.originalHeight,
                                    step: 1,
                                    size: "small",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateElementPosition("y")
                                    },
                                  },
                                  model: {
                                    value: _vm.elementY,
                                    callback: function ($$v) {
                                      _vm.elementY = $$v
                                    },
                                    expression: "elementY",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("Y"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "panel-item ml20" }, [
                          _c("div", { staticClass: "item-label" }, [
                            _vm._v("尺寸："),
                          ]),
                          _c(
                            "div",
                            { staticClass: "size-inputs" },
                            [
                              _c(
                                "el-input-number",
                                {
                                  attrs: {
                                    min: 20,
                                    max: _vm.originalWidth,
                                    step: 1,
                                    size: "small",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateElementSize("width")
                                    },
                                  },
                                  model: {
                                    value: _vm.elementWidth,
                                    callback: function ($$v) {
                                      _vm.elementWidth = $$v
                                    },
                                    expression: "elementWidth",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("宽"),
                                  ]),
                                ],
                                2
                              ),
                              _c(
                                "el-input-number",
                                {
                                  attrs: {
                                    min: 20,
                                    max: _vm.originalHeight,
                                    step: 1,
                                    size: "small",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateElementSize("height")
                                    },
                                  },
                                  model: {
                                    value: _vm.elementHeight,
                                    callback: function ($$v) {
                                      _vm.elementHeight = $$v
                                    },
                                    expression: "elementHeight",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("高"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm.selectedElement.type === "music"
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "right-title" }, [
                            _vm._v(" 音乐： "),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleReplace(_vm.selectedElement)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("tip.select")))]
                          ),
                          _vm.musicUrl
                            ? _c("div", { staticClass: "flex-column" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-row-center",
                                    staticStyle: { "margin-top": "5px" },
                                  },
                                  [
                                    _c("audio", {
                                      ref: "audioPlayer",
                                      staticClass: "f-grow",
                                      attrs: {
                                        controls: "",
                                        src: _vm.musicUrl,
                                      },
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-circle-close pointer ml5",
                                      on: { click: _vm.delMusic },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex-row-center mt5" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#606266",
                                          "font-size": "14px",
                                          "margin-right": "12px",
                                          "font-weight": "700",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("editVideo.musicVolume")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("el-slider", {
                                      staticClass: "f-grow",
                                      attrs: {
                                        step: 0.1,
                                        max: 10,
                                        min: 0,
                                        "show-input": "",
                                      },
                                      model: {
                                        value: _vm.gain,
                                        callback: function ($$v) {
                                          _vm.gain = $$v
                                        },
                                        expression: "gain",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.materialDrawerVisible },
        on: {
          "update:visible": function ($event) {
            _vm.materialDrawerVisible = $event
          },
          selectionConfirmed: _vm.handleMaterialSelected,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "publicDialog",
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "50%",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("issueItem.translateTo"))),
              ]),
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.handleCancel },
              }),
            ]),
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                placeholder: _vm.$t("issueItem.translateLanguage"),
                filterable: "",
                clearable: "",
                "default-first-option": true,
              },
              model: {
                value: _vm.language,
                callback: function ($$v) {
                  _vm.language = $$v
                },
                expression: "language",
              },
            },
            _vm._l(_vm.languageList, function (dict) {
              return _c("el-option", {
                key: dict.bdCode,
                attrs: { label: dict.language, value: dict.bdCode },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancelBtn btnWidth120",
                  on: { click: _vm.handleCancel },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "submitBtn btnWidth120",
                  attrs: { loading: _vm.loadingTranslate },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "title" }, [_vm._v("在线制作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }