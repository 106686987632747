<template>
  <div
    class="grid-item"
    @click.stop="toggleSelection"
    @mouseover="mouseoverItem"
    @mouseleave="mouseleaveItem"
    :style="{ zIndex: item.hover ? 10 : 'auto' }"
  >
    <!-- 视频封面 -->
    <div class="left-section">
      <div class="video-grid-cover">
        <img
          :src="item.cover"
          class="video-grid-cover"
        />
        <div
          v-if="item.hover"
          class="video-text-container flex-column"
        >
          <el-button
            class="video-text"
            @click="$emit('view-click', item)"
          >
            在线制作
          </el-button>
        </div>
      </div>
    </div>
    <div
      class="grid-title single-line-ellipsis"
      :class="item.hover?'grid-title-hover':''"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoItem",
  components: {},
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {},
      addingTag: false,
      newTag: "",
      mediaUrl: {},
    };
  },
  watch: {
    video: {
      handler(newVal, oldVal) {
        this.item = { ...newVal };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleSelection() {
      // this.$emit("view-click", this.item);
    },
    mouseleaveItem() {
      this.$set(this.item, "hover", false);
    },
    mouseoverItem() {
      this.$set(this.item, "hover", true);
    },
  },
};
</script>

<style scoped lang="scss">
.grid-item {
  // max-width: 216px;
  margin: 0 10px 10px 0;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  z-index: auto;
}
.grid-item:hover {
  background: #f0f3fa;
}
.left-section {
  position: relative;
}
.video-grid-cover {
  width: 100%;
  aspect-ratio: 9 / 16; /* 设置宽高比为 4:3 */
  // height: 390px;
  border-radius: 5px;
  // border: 1px solid #dfdfdf;
  cursor: pointer;
  object-fit: cover; /* 确保视频不会拉伸 */
}
.video-text-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  // border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
}
.video-text {
  background: #0156ff;
  // background-image: linear-gradient(90deg, #0089FF 0%, #6E16D1 100%);
  border-radius: 12px;
  color: #ffffff;
}
.video-text:hover {
  box-shadow: 0 0 10px #ccc;
}
.grid-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 18px;
  font-style: normal;
  margin-top: 8px;
  padding: 12px;
}
.grid-title-hover {
  color: #0156ff;
}
</style>
