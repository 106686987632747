var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "grid-item",
      style: { zIndex: _vm.item.hover ? 10 : "auto" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
        mouseover: _vm.mouseoverItem,
        mouseleave: _vm.mouseleaveItem,
      },
    },
    [
      _c("div", { staticClass: "left-section" }, [
        _c("div", { staticClass: "video-grid-cover" }, [
          _c("img", {
            staticClass: "video-grid-cover",
            attrs: { src: _vm.item.cover },
          }),
          _vm.item.hover
            ? _c(
                "div",
                { staticClass: "video-text-container flex-column" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "video-text",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("view-click", _vm.item)
                        },
                      },
                    },
                    [_vm._v(" 在线制作 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "grid-title single-line-ellipsis",
          class: _vm.item.hover ? "grid-title-hover" : "",
        },
        [_vm._v(" " + _vm._s(_vm.item.title) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }