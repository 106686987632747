var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-editor" },
    [
      _c("div", { staticClass: "title-header" }, [
        _c("div", { staticClass: "title-header-text" }, [
          _vm._v("视频模板编辑"),
        ]),
        _c(
          "div",
          { staticClass: "flex-row-center" },
          [
            _c(
              "el-button",
              { attrs: { disabled: !_vm.canUndo }, on: { click: _vm.undo } },
              [
                _c("i", { staticClass: "el-icon-refresh-left" }),
                _vm._v(" 撤销 "),
              ]
            ),
            _c(
              "el-button",
              { attrs: { disabled: !_vm.canRedo }, on: { click: _vm.redo } },
              [
                _c("i", { staticClass: "el-icon-refresh-right" }),
                _vm._v(" 恢复 "),
              ]
            ),
            _c(
              "el-button",
              {
                staticClass: "gradient-button",
                attrs: { type: "primary" },
                on: { click: _vm.saveTemplate },
              },
              [_vm._v("视频制作")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "editor-content" }, [
        _c(
          "div",
          { staticClass: "editor-menu" },
          _vm._l(_vm.menus, function (menu, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "menu-item",
                class: { active: _vm.currentMenu === menu.type },
                on: {
                  click: function ($event) {
                    return _vm.selectMenu(menu.type)
                  },
                },
              },
              [
                _c("i", { class: menu.icon }),
                _c("span", [_vm._v(_vm._s(menu.title))]),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "editor-main" }, [
          _c("div", { staticClass: "editor-canvas-wrapper" }, [
            _c(
              "div",
              { staticClass: "editor-canvas", style: _vm.canvasStyle },
              [
                _vm._l(_vm.pageList, function (element, index) {
                  return [
                    element.type === "text"
                      ? _c(
                          "div",
                          {
                            directives: [
                              { name: "draggable", rawName: "v-draggable" },
                            ],
                            key: "text-" + (element.id || index),
                            class: { active: _vm.selectedElement === element },
                            style: _vm.getElementStyle(element),
                            on: {
                              click: function ($event) {
                                return _vm.selectElement(element)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-close delete-icon",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteElement(element)
                                },
                              },
                            }),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(element.content || ""),
                              },
                            }),
                          ]
                        )
                      : element.type === "file"
                      ? _c(
                          "div",
                          {
                            directives: [
                              { name: "draggable", rawName: "v-draggable" },
                            ],
                            key: "image-" + (element.id || index),
                            class: { active: _vm.selectedElement === element },
                            style: _vm.getElementStyle(element),
                            on: {
                              click: function ($event) {
                                return _vm.selectElement(element)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-close delete-icon",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteElement(element)
                                },
                              },
                            }),
                            _c("img", { attrs: { src: element.url } }),
                          ]
                        )
                      : element.type === "video"
                      ? _c(
                          "div",
                          {
                            directives: [
                              { name: "draggable", rawName: "v-draggable" },
                            ],
                            key: "video-" + (element.id || index),
                            staticClass: "element video-element relative",
                            class: { active: _vm.selectedElement === element },
                            style: _vm.getElementStyle(element),
                            on: {
                              click: function ($event) {
                                return _vm.selectElement(element)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-close delete-icon",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteElement(element)
                                },
                              },
                            }),
                            element.propsValue &&
                            element.propsValue.videoSrc &&
                            element.propsValue.videoSrc.url
                              ? _c("video", {
                                  attrs: {
                                    src: element.propsValue.videoSrc.url,
                                    controls: "",
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "editor-panel" },
          [
            _c(
              "div",
              { staticClass: "panel-header" },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.currentMenu
                        ? _vm.getMenuTitle(_vm.currentMenu)
                        : "元素属性"
                    )
                  ),
                ]),
                _vm.currentMenu && _vm.currentMenu !== "music"
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.addElementByType(_vm.currentMenu)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(
                          " 添加" +
                            _vm._s(_vm.getMenuTitle(_vm.currentMenu)) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.currentMenu === "music" && !_vm.selectedElement
              ? _c(
                  "div",
                  { staticClass: "empty-panel" },
                  [
                    _c("div", { staticClass: "empty-tip" }, [
                      _vm._v("当前未设置背景音乐"),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: { click: _vm.chooseBgm },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" 添加背景音乐 "),
                      ]
                    ),
                  ],
                  1
                )
              : _vm.selectedElement
              ? [
                  _vm.selectedElement.type === "text"
                    ? [
                        _c(
                          "div",
                          { staticClass: "panel-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("文本内容"),
                            ]),
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                maxlength: "200",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.selectedElement.propsValue.text,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedElement.propsValue,
                                    "text",
                                    $$v
                                  )
                                },
                                expression: "selectedElement.propsValue.text",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "panel-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("字体"),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                model: {
                                  value: _vm.selectedElement.propsValue.font,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.propsValue,
                                      "font",
                                      $$v
                                    )
                                  },
                                  expression: "selectedElement.propsValue.font",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.font_typeface,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "panel-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("字体大小"),
                            ]),
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: { min: 12, max: 100 },
                              model: {
                                value: _vm.selectedElement.commonStyle.fontSize,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedElement.commonStyle,
                                    "fontSize",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedElement.commonStyle.fontSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "panel-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("字体颜色"),
                            ]),
                            _c("el-color-picker", {
                              attrs: { "show-alpha": "" },
                              model: {
                                value: _vm.selectedElement.commonStyle.color,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedElement.commonStyle,
                                    "color",
                                    $$v
                                  )
                                },
                                expression: "selectedElement.commonStyle.color",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "panel-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("字间距"),
                            ]),
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: { min: 0, max: 20 },
                              model: {
                                value:
                                  _vm.selectedElement.commonStyle.letterSpacing,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedElement.commonStyle,
                                    "letterSpacing",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedElement.commonStyle.letterSpacing",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "panel-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("样式"),
                            ]),
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value:
                                    _vm.selectedElement.propsValue.fontStyles,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.propsValue,
                                      "fontStyles",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedElement.propsValue.fontStyles",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "italic" } },
                                  [_vm._v("斜体")]
                                ),
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "bold" } },
                                  [_vm._v("粗体")]
                                ),
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "underline" } },
                                  [_vm._v("下划线")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "panel-item" }, [
                          _c("div", { staticClass: "item-label" }, [
                            _vm._v("位置"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "position-inputs" },
                            [
                              _c("el-input-number", {
                                attrs: { label: "X" },
                                model: {
                                  value: _vm.selectedElement.commonStyle.left,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.commonStyle,
                                      "left",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedElement.commonStyle.left",
                                },
                              }),
                              _c("el-input-number", {
                                attrs: { label: "Y" },
                                model: {
                                  value: _vm.selectedElement.commonStyle.top,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.commonStyle,
                                      "top",
                                      $$v
                                    )
                                  },
                                  expression: "selectedElement.commonStyle.top",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "panel-item" }, [
                          _c("div", { staticClass: "item-label" }, [
                            _vm._v("尺寸"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "size-inputs" },
                            [
                              _c("el-input-number", {
                                attrs: { label: "宽" },
                                model: {
                                  value: _vm.selectedElement.commonStyle.width,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.commonStyle,
                                      "width",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedElement.commonStyle.width",
                                },
                              }),
                              _c("el-input-number", {
                                attrs: { label: "高" },
                                model: {
                                  value: _vm.selectedElement.commonStyle.height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.commonStyle,
                                      "height",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedElement.commonStyle.height",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  ["file", "video"].includes(_vm.selectedElement.type)
                    ? [
                        _c(
                          "div",
                          { staticClass: "panel-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v(
                                "替换" +
                                  _vm._s(
                                    _vm.selectedElement.type === "file"
                                      ? "图片"
                                      : "视频"
                                  )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedElement.type === "file"
                                      ? _vm.replaceImage()
                                      : _vm.replaceVideo()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " 选择" +
                                    _vm._s(
                                      _vm.selectedElement.type === "file"
                                        ? "图片"
                                        : "视频"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "panel-item" }, [
                          _c("div", { staticClass: "item-label" }, [
                            _vm._v("位置"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "position-inputs" },
                            [
                              _c("el-input-number", {
                                attrs: { label: "X" },
                                model: {
                                  value: _vm.selectedElement.commonStyle.left,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.commonStyle,
                                      "left",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedElement.commonStyle.left",
                                },
                              }),
                              _c("el-input-number", {
                                attrs: { label: "Y" },
                                model: {
                                  value: _vm.selectedElement.commonStyle.top,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.commonStyle,
                                      "top",
                                      $$v
                                    )
                                  },
                                  expression: "selectedElement.commonStyle.top",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "panel-item" }, [
                          _c("div", { staticClass: "item-label" }, [
                            _vm._v("尺寸"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "size-inputs" },
                            [
                              _c("el-input-number", {
                                attrs: { label: "宽" },
                                model: {
                                  value: _vm.selectedElement.commonStyle.width,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.commonStyle,
                                      "width",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedElement.commonStyle.width",
                                },
                              }),
                              _c("el-input-number", {
                                attrs: { label: "高" },
                                model: {
                                  value: _vm.selectedElement.commonStyle.height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.selectedElement.commonStyle,
                                      "height",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedElement.commonStyle.height",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.selectedElement.type === "audio"
                    ? [
                        _c("div", { staticClass: "panel-item" }, [
                          _c("div", { staticClass: "item-label" }, [
                            _vm._v("背景音乐"),
                          ]),
                          _c("div", { staticClass: "music-player" }, [
                            _vm.selectedElement.propsValue.audioSrc
                              ? _c("audio", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    src: _vm.selectedElement.propsValue
                                      .audioSrc,
                                    controls: "",
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "music-actions" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small" },
                                    on: { click: _vm.chooseBgm },
                                  },
                                  [_vm._v("选择音乐")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "danger" },
                                    on: { click: _vm.deleteBgm },
                                  },
                                  [_vm._v("删除音乐")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "panel-item" },
                          [
                            _c("div", { staticClass: "item-label" }, [
                              _vm._v("音量"),
                            ]),
                            _c("el-slider", {
                              attrs: { max: 100, min: 0, "show-input": "" },
                              model: {
                                value: _vm.selectedElement.propsValue.volume,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedElement.propsValue,
                                    "volume",
                                    $$v
                                  )
                                },
                                expression: "selectedElement.propsValue.volume",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              : _c("div", { staticClass: "empty-panel" }, [
                  _c("div", { staticClass: "empty-tip" }, [
                    _vm._v("请在画板上选择需要编辑的元素"),
                  ]),
                ]),
          ],
          2
        ),
      ]),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.materialDrawerVisible },
        on: {
          "update:visible": function ($event) {
            _vm.materialDrawerVisible = $event
          },
          selectionConfirmed: _vm.handleMaterialSelected,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }