<template>
  <div class="faddish-container">
    <div class="flex-row f-end">
      <!-- <div class="title-header-text">模板快剪</div> -->
      <div class="flex-row-center">
        <div style="width: 400px;margin:0 5px;">
          <el-input
            class="account-input"
            v-model="queryParams.searchValue"
            :placeholder="$t('faddish.searchPlaceholder')"
            @keyup.enter.native="handleQuery"
            clearable
          >
            <i
              slot="prefix"
              class="el-input__icon iconfont icon-sousuotubiao"
              @click="handleQuery"
            ></i>
          </el-input>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('refresh')"
          placement="top"
        >
          <el-button
            size="mini"
            circle
            icon="el-icon-refresh"
            @click="refresh"
          />
        </el-tooltip>
      </div>
    </div>
    <div
      class="faddish-content"
      v-loading="loading"
    >
      <div class="flex-row f-wrap">
        <div
          class="video-parent flex-center"
          v-for="(video, index) in list"
          :key="index"
        >
          <grid-item
            :video="video"
            @view-click="handleView"
          />
        </div>
      </div>
      <el-empty
        v-if="list.length==0"
        :image-size="200"
      ></el-empty>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        :pageSizes="[12,24,36,48,60]"
        @pagination="getList"
      />
    </div>
  </div>
</template>
<script>
import { listVideoTemplate } from "../../api/ai/template";
import GridItem from "./components/GridItem";
export default {
  name: "Template",
  components: {
    GridItem,
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 24,
        searchValue: "",
      },
      loading: false,
      list: [],
      // 总条数
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //爆款列表
    getList() {
      this.loading = true;
      listVideoTemplate(this.queryParams)
        .then((response) => {
          this.list = response.rows;
          this.total = response.total;
          this.list.forEach((item) => {
            item.hover = false;
            if (item.postContent) {
              item.postContent = item.postContent.split("#")[0].trim();
            }
            if (item.tags) {
              item.labels = JSON.parse(item.tags);
            } else {
              item.labels = [];
            }
            console.log(item.labels);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查看详情
    handleView(item) {
      this.$router.push({
        path: `/template/editor/${item.id}`,
      });
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    refresh() {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.faddish-container {
  margin: 16px;
}
.faddish-content {
  background: #ffffff;
  //box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  margin-top: 16px;
  padding: 24px;
}
.custom-radio-group {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.custom-radio-group ::v-deep.el-radio {
  margin-right: 5px;
}
.custom-radio-group ::v-deep.el-radio.is-bordered.is-checked {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
.custom-radio-group ::v-deep.el-radio__input {
  display: none; /* 隐藏原点 */
}
.video-parent {
  width: calc(16% - 10px);
}
</style>
