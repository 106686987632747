var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 4, xs: 24 } }, [
            _c(
              "div",
              { staticClass: "head-container" },
              [
                _c("el-input", {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: {
                    placeholder: "请输入部门名称",
                    clearable: "",
                    size: "small",
                    "prefix-icon": "el-icon-search",
                    maxlength: "50",
                  },
                  model: {
                    value: _vm.deptName,
                    callback: function ($$v) {
                      _vm.deptName = $$v
                    },
                    expression: "deptName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "head-container" },
              [
                _c("el-tree", {
                  ref: "tree",
                  attrs: {
                    data: _vm.deptOptions,
                    props: _vm.defaultProps,
                    "expand-on-click-node": false,
                    "filter-node-method": _vm.filterNode,
                    "node-key": "id",
                    "default-expand-all": "",
                    "highlight-current": "",
                  },
                  on: { "node-click": _vm.handleNodeClick },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 20, xs: 24 } },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSearch,
                      expression: "showSearch",
                    },
                  ],
                  ref: "queryForm",
                  attrs: {
                    model: _vm.queryParams,
                    size: "small",
                    inline: true,
                    "label-width": "68px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户名", prop: "userName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        attrs: {
                          placeholder: "请输入用户名",
                          clearable: "",
                          maxlength: "50",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          },
                        },
                        model: {
                          value: _vm.queryParams.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "userName", $$v)
                          },
                          expression: "queryParams.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码", prop: "phonenumber" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        attrs: {
                          placeholder: "请输入手机号码",
                          clearable: "",
                          maxlength: "11",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          },
                        },
                        model: {
                          value: _vm.queryParams.phonenumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "phonenumber", $$v)
                          },
                          expression: "queryParams.phonenumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("status.status"), prop: "status" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { placeholder: "用户状态", clearable: "" },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "status", $$v)
                            },
                            expression: "queryParams.status",
                          },
                        },
                        _vm._l(
                          _vm.dict.type.sys_normal_disable,
                          function (dict) {
                            return _c("el-option", {
                              key: dict.value,
                              attrs: { label: dict.label, value: dict.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("time.createTime") } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "240px" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "-",
                          "start-placeholder": _vm.$t("time.startDate"),
                          "end-placeholder": _vm.$t("time.endingDate"),
                        },
                        model: {
                          value: _vm.dateRange,
                          callback: function ($$v) {
                            _vm.dateRange = $$v
                          },
                          expression: "dateRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "mini",
                          },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v(_vm._s(_vm.$t("search")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "mini" },
                          on: { click: _vm.resetQuery },
                        },
                        [_vm._v(_vm._s(_vm.$t("reset")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mb8", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["system:user:add"],
                              expression: "['system:user:add']",
                            },
                          ],
                          attrs: {
                            type: "primary",
                            plain: "",
                            icon: "el-icon-plus",
                            size: "mini",
                          },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v(_vm._s(_vm.$t("add")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["system:user:edit"],
                              expression: "['system:user:edit']",
                            },
                          ],
                          attrs: {
                            type: "success",
                            plain: "",
                            icon: "iconfont icon-edit",
                            size: "mini",
                            disabled: _vm.single,
                          },
                          on: { click: _vm.handleUpdate },
                        },
                        [_vm._v(_vm._s(_vm.$t("update")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["system:user:import"],
                              expression: "['system:user:import']",
                            },
                          ],
                          attrs: {
                            type: "info",
                            plain: "",
                            icon: "el-icon-upload2",
                            size: "mini",
                          },
                          on: { click: _vm.handleImport },
                        },
                        [_vm._v("导入")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["system:user:export"],
                              expression: "['system:user:export']",
                            },
                          ],
                          attrs: {
                            type: "warning",
                            plain: "",
                            icon: "iconfont icon-daoru",
                            size: "mini",
                          },
                          on: { click: _vm.handleExport },
                        },
                        [_vm._v(_vm._s(_vm.$t("export")))]
                      ),
                    ],
                    1
                  ),
                  _c("right-toolbar", {
                    attrs: { showSearch: _vm.showSearch, columns: _vm.columns },
                    on: {
                      "update:showSearch": function ($event) {
                        _vm.showSearch = $event
                      },
                      "update:show-search": function ($event) {
                        _vm.showSearch = $event
                      },
                      queryTable: _vm.getList,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { data: _vm.userList, stripe: "" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "50", align: "center" },
                  }),
                  _vm.columns[0].visible
                    ? _c("el-table-column", {
                        key: "userId",
                        attrs: {
                          label: "用户编号",
                          align: "center",
                          prop: "userId",
                          width: "170",
                        },
                      })
                    : _vm._e(),
                  _vm.columns[1].visible
                    ? _c("el-table-column", {
                        key: "userName",
                        attrs: {
                          label: "用户名",
                          align: "center",
                          prop: "userName",
                          "show-overflow-tooltip": true,
                        },
                      })
                    : _vm._e(),
                  _vm.columns[2].visible
                    ? _c("el-table-column", {
                        key: "nickName",
                        attrs: {
                          label: "用户昵称",
                          align: "center",
                          prop: "nickName",
                          "show-overflow-tooltip": true,
                        },
                      })
                    : _vm._e(),
                  _vm.columns[3].visible
                    ? _c("el-table-column", {
                        key: "deptName",
                        attrs: {
                          label: "部门",
                          align: "center",
                          prop: "dept.deptName",
                          "show-overflow-tooltip": true,
                        },
                      })
                    : _vm._e(),
                  _vm.columns[4].visible
                    ? _c("el-table-column", {
                        key: "phonenumber",
                        attrs: {
                          label: "手机号码",
                          align: "center",
                          prop: "phonenumber",
                          width: "120",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    key: "userType",
                    attrs: {
                      label: "用户类型",
                      align: "center",
                      prop: "userType",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("dict-tag", {
                              attrs: {
                                options: _vm.dict.type.user_type,
                                value: scope.row.userType,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    key: "registerType",
                    attrs: {
                      label: _vm.$t("register.registerType"),
                      align: "center",
                      prop: "registerType",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("dict-tag", {
                              attrs: {
                                options: _vm.dict.type.user_source,
                                value: scope.row.registerType,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    key: "loginType",
                    attrs: {
                      label: "登录类型",
                      align: "center",
                      prop: "loginType",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("dict-tag", {
                              attrs: {
                                options: _vm.dict.type.user_source,
                                value: scope.row.loginType,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.columns[5].visible
                    ? _c("el-table-column", {
                        key: "status",
                        attrs: {
                          label: _vm.$t("status.status"),
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": "0",
                                      "inactive-value": "1",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleStatusChange(scope.row)
                                      },
                                    },
                                    model: {
                                      value: scope.row.status,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "status", $$v)
                                      },
                                      expression: "scope.row.status",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3955094654
                        ),
                      })
                    : _vm._e(),
                  _vm.columns[6].visible
                    ? _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("time.createTime"),
                          align: "center",
                          prop: "createTime",
                          width: "160",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseTime(scope.row.createTime)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3078210614
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("operate"),
                      align: "center",
                      width: "160",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.userId !== 1
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["system:user:edit"],
                                          expression: "['system:user:edit']",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        icon: "iconfont icon-edit",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdate(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("update")))]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["system:user:remove"],
                                          expression: "['system:user:remove']",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        icon: "iconfont icon-delete",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("remove")))]
                                  ),
                                  _c(
                                    "el-dropdown",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: [
                                            "system:user:resetPwd",
                                            "system:user:edit",
                                          ],
                                          expression:
                                            "['system:user:resetPwd', 'system:user:edit']",
                                        },
                                      ],
                                      attrs: { size: "mini" },
                                      on: {
                                        command: function (command) {
                                          return _vm.handleCommand(
                                            command,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "text",
                                            icon: "el-icon-d-arrow-right",
                                          },
                                        },
                                        [_vm._v("更多")]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              directives: [
                                                {
                                                  name: "hasPermi",
                                                  rawName: "v-hasPermi",
                                                  value: [
                                                    "system:user:resetPwd",
                                                  ],
                                                  expression:
                                                    "['system:user:resetPwd']",
                                                },
                                              ],
                                              attrs: {
                                                command: "handleResetPwd",
                                                icon: "el-icon-key",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("forget.resetPassword")
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            {
                                              directives: [
                                                {
                                                  name: "hasPermi",
                                                  rawName: "v-hasPermi",
                                                  value: ["system:user:edit"],
                                                  expression:
                                                    "['system:user:edit']",
                                                },
                                              ],
                                              attrs: {
                                                command: "handleAuthRole",
                                                icon: "el-icon-circle-check",
                                              },
                                            },
                                            [_vm._v("分配角色")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户昵称", prop: "nickName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户昵称",
                              maxlength: "50",
                            },
                            model: {
                              value: _vm.form.nickName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nickName", $$v)
                              },
                              expression: "form.nickName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "归属部门", prop: "deptId" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.deptOptions,
                              "show-count": true,
                              placeholder: "请选择归属部门",
                            },
                            model: {
                              value: _vm.form.deptId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deptId", $$v)
                              },
                              expression: "form.deptId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码", prop: "phonenumber" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入手机号码",
                              maxlength: "11",
                            },
                            model: {
                              value: _vm.form.phonenumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phonenumber", $$v)
                              },
                              expression: "form.phonenumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("login.email"),
                            prop: "email",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入邮箱",
                              maxlength: "50",
                            },
                            model: {
                              value: _vm.form.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.userId == undefined
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "用户名称", prop: "userName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入用户名称",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.form.userName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "userName", $$v)
                                  },
                                  expression: "form.userName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.userId == undefined
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "用户密码", prop: "password" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入用户密码",
                                  type: "password",
                                  maxlength: "50",
                                  "show-password": "",
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户性别" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择性别" },
                              model: {
                                value: _vm.form.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sex", $$v)
                                },
                                expression: "form.sex",
                              },
                            },
                            _vm._l(_vm.dict.type.sys_user_sex, function (dict) {
                              return _c("el-option", {
                                key: dict.value,
                                attrs: { label: dict.label, value: dict.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("status.status") } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            _vm._l(
                              _vm.dict.type.sys_normal_disable,
                              function (dict) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: dict.value,
                                    attrs: { label: dict.value },
                                  },
                                  [_vm._v(_vm._s(dict.label))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "岗位" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                placeholder: "请选择岗位",
                              },
                              model: {
                                value: _vm.form.postIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "postIds", $$v)
                                },
                                expression: "form.postIds",
                              },
                            },
                            _vm._l(_vm.postOptions, function (item) {
                              return _c("el-option", {
                                key: item.postId,
                                attrs: {
                                  label: item.postName,
                                  value: item.postId,
                                  disabled: item.status == 1,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "角色" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                placeholder: "请选择角色",
                              },
                              model: {
                                value: _vm.form.roleIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "roleIds", $$v)
                                },
                                expression: "form.roleIds",
                              },
                            },
                            _vm._l(_vm.roleOptions, function (item) {
                              return _c("el-option", {
                                key: item.roleId,
                                attrs: {
                                  label: item.roleName,
                                  value: item.roleId,
                                  disabled: item.status == 1,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "200",
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.upload.title,
            visible: _vm.upload.open,
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.upload, "open", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                limit: 1,
                accept: ".xlsx, .xls",
                headers: _vm.upload.headers,
                action:
                  _vm.upload.url + "?updateSupport=" + _vm.upload.updateSupport,
                disabled: _vm.upload.isUploading,
                "on-progress": _vm.handleFileUploadProgress,
                "on-success": _vm.handleFileSuccess,
                "auto-upload": false,
                drag: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip text-center",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.upload.updateSupport,
                          callback: function ($$v) {
                            _vm.$set(_vm.upload, "updateSupport", $$v)
                          },
                          expression: "upload.updateSupport",
                        },
                      }),
                      _vm._v(" 是否更新已经存在的用户数据 "),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: { type: "primary", underline: false },
                      on: { click: _vm.importTemplate },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitFileForm },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.upload.open = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }