var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "faddish-container" }, [
    _c("div", { staticClass: "flex-row f-end" }, [
      _c(
        "div",
        { staticClass: "flex-row-center" },
        [
          _c(
            "div",
            { staticStyle: { width: "400px", margin: "0 5px" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "account-input",
                  attrs: {
                    placeholder: _vm.$t("faddish.searchPlaceholder"),
                    clearable: "",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleQuery($event)
                    },
                  },
                  model: {
                    value: _vm.queryParams.searchValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "searchValue", $$v)
                    },
                    expression: "queryParams.searchValue",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon iconfont icon-sousuotubiao",
                    attrs: { slot: "prefix" },
                    on: { click: _vm.handleQuery },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.$t("refresh"),
                placement: "top",
              },
            },
            [
              _c("el-button", {
                attrs: { size: "mini", circle: "", icon: "el-icon-refresh" },
                on: { click: _vm.refresh },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "faddish-content",
      },
      [
        _c(
          "div",
          { staticClass: "flex-row f-wrap" },
          _vm._l(_vm.list, function (video, index) {
            return _c(
              "div",
              { key: index, staticClass: "video-parent flex-center" },
              [
                _c("grid-item", {
                  attrs: { video: video },
                  on: { "view-click": _vm.handleView },
                }),
              ],
              1
            )
          }),
          0
        ),
        _vm.list.length == 0
          ? _c("el-empty", { attrs: { "image-size": 200 } })
          : _vm._e(),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            total: _vm.total,
            page: _vm.queryParams.pageNum,
            limit: _vm.queryParams.pageSize,
            pageSizes: [12, 24, 36, 48, 60],
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.queryParams, "pageNum", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.queryParams, "pageSize", $event)
            },
            pagination: _vm.getList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }