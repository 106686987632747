<template>
  <div
    class="editor-container"
    v-loading="loading"
  >
    <!-- 顶部操作栏 -->
    <div class="editor-header">
      <div class="left">
        <div class="title">在线制作</div>
      </div>
      <el-button
        class="gradient-button"
        type="primary"
        @click="handleSave"
      >生成视频</el-button>
    </div>

    <!-- 主体内容区 -->
    <div class="editor-main">
      <!-- 左侧工具栏 -->
      <div class="editor-tools">
        <div
          v-for="tool in tools"
          :key="tool.type"
          class="tool-item"
          :class="{ active: currentTool === tool.type }"
          @click="selectTool(tool.type)"
        >
          <i
            class="iconfont iconfont-tool"
            :class="tool.icon"
          ></i>
          <span>{{ tool.name }}</span>
        </div>
      </div>

      <!-- 中间画布区域 -->
      <div class="editor-canvas">
        <div
          class="canvas-container"
          :style="canvasStyle"
        >
          <div
            v-for="(element, index) in pageData.pageList"
            :key="element.id || index"
            class="element"
            :class="[
              element.type === 'image'||element.type === 'file'|| element.type === 'video' ? 'media-element' : 'text-element',
              { active: selectedElement && selectedElement.id === element.id }
            ]"
            :style="getElementStyle(element)"
            @click="selectElement(element)"
            v-draggable="element"
          >
            <template v-if="element.type === 'image'||element.type === 'file'">
              <!-- 图片元素 -->
              <img
                :src="element.url"
                style="width: 100%; height: 100%; object-fit: cover;"
              />
            </template>
            <template v-else-if="element.type === 'video'">
              <!-- 视频元素 -->
              <video
                v-if="getTypeByUrl(element.url) === 'video'"
                :src="element.url"
                style="width: 100%; height: 100%; object-fit: cover;"
                controls
              ></video>
              <img
                v-else
                :src="element.url"
                style="width: 100%; height: 100%; object-fit: cover;"
              />
            </template>
            <template v-else-if="element.type === 'text'">
              <div v-html="formattedContent(element.content)  || ''"></div>
            </template>
          </div>
        </div>
        <!-- 设置 -->
        <div class="editor-canvas-settings flex-column-center">
          <el-tooltip
            class="settings-item"
            effect="dark"
            content="复制"
            placement="right"
          >
            <i
              class="iconfont icon-fuzhi"
              :class="{ 'disabled': !selectedElement }"
              @click="copyElement"
            ></i>
          </el-tooltip>
          <el-tooltip
            class="settings-item"
            effect="dark"
            content="删除"
            placement="right"
          >
            <i
              class="iconfont icon-delete"
              :class="{ 'disabled': !selectedElement }"
              @click="deleteElement"
            ></i>
          </el-tooltip>
          <el-tooltip
            class="settings-item"
            effect="dark"
            content="字体放大"
            placement="right"
          >
            <i
              class="iconfont icon-zitifangda"
              :class="{ 'disabled': !canEditFont }"
              @click="enlargementFont"
            ></i>
          </el-tooltip>
          <el-tooltip
            class="settings-item"
            effect="dark"
            content="字体缩小"
            placement="right"
          >
            <i
              class="iconfont icon-zitisuoxiao"
              :class="{ 'disabled': !canEditFont }"
              @click="reductionFont"
            ></i>
          </el-tooltip>
          <el-tooltip
            class="settings-item"
            effect="dark"
            content="撤销"
            placement="right"
          >
            <i
              class="iconfont icon-chexiao"
              :class="{ 'disabled': !canUndo }"
              @click="undo"
            ></i>
          </el-tooltip>
          <el-tooltip
            class="settings-item"
            effect="dark"
            content="恢复"
            placement="right"
          >
            <i
              class="iconfont icon-huifu"
              :class="{ 'disabled': !canRedo }"
              @click="redo"
            ></i>
          </el-tooltip>
        </div>
      </div>

      <!-- 右侧属性面板 -->
      <div class="editor-panel">
        <template v-if="selectedElement">
          <!-- 文本属性编辑 -->
          <div v-if="selectedElement.type === 'text'">
            <div class="right-title">文本内容：</div>
            <div
              class="textInput"
              v-loading="loadingTranslate"
            >
              <!-- show-word-limit -->
              <el-input
                maxlength="500"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6}"
                v-model="selectedElement.content"
                :placeholder="$t('tip.input')"
                @change="addHistory"
              >
              </el-input>
              <div class="flexInputBtn f-between">
                <div class="flex-row-center">
                  <div
                    class="btnBox"
                    @click="chooseLanguage"
                  ><i class="iconfont icon-danyuanpeizhi"></i>{{ $t('issueItem.translate') }}</div>
                  <div
                    class="btnBox"
                    @click="rewrite('sc')"
                  ><i class="iconfont icon-zongjie"></i>{{ $t('subtitle.sumup') }}</div>
                  <div
                    class="btnBox"
                    @click="rewrite('sx')"
                  ><i class="iconfont icon-a-danyuanpeizhi4"></i>{{ $t('subtitle.abbreviation') }}</div>
                  <div
                    class="btnBox"
                    @click="rewrite('kx')"
                  ><i class="iconfont icon-a-danyuanpeizhi5"></i>{{ $t('subtitle.expand') }}</div>
                  <div
                    class="btnBox"
                    @click="rewrite('cx')"
                  ><i class="iconfont icon-a-danyuanpeizhi6"></i>{{ $t('subtitle.rewrite') }}</div>
                </div>
                <i class="iconfont icon-delete pointer"></i>
              </div>
            </div>
            <el-form
              label-position="right"
              label-width="80px"
            >
              <el-collapse v-model="activeNames">
                <el-collapse-item
                  title="字体"
                  name="1"
                >
                  <el-form-item label="字体名称">
                    <el-select
                      v-model="selectedElement.cssObj['font-family']"
                      filterable
                      :default-first-option="true"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="dict in dict.type.font_typeface"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                      />
                    </el-select>
                  </el-form-item>

                  <el-form-item label="字体大小">
                    <el-input-number
                      v-model="selectedElement.cssObj['font-size']"
                      :min="12"
                      :max="200"
                      :step="1"
                      style="width: 100%"
                    />
                  </el-form-item>

                  <el-form-item label="字体颜色">
                    <el-color-picker
                      v-model="selectedElement.cssObj.color"
                      show-alpha
                    />
                  </el-form-item>

                  <el-form-item label="字间距">
                    <el-input-number
                      v-model="selectedElement.cssObj['letter-spacing']"
                      :min="0"
                      :max="50"
                      :step="1"
                      style="width: 100%"
                    />
                  </el-form-item>

                  <el-form-item label="对齐方式">
                    <el-radio-group
                      v-model="selectedElement.cssObj['text-align']"
                      @input="updateElementAlignment"
                    >
                      <el-radio label="left">左对齐</el-radio>
                      <el-radio label="center">居中</el-radio>
                      <el-radio label="right">右对齐</el-radio>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="字体粗细">
                    <el-radio-group v-model="selectedElement.cssObj['font-weight']">
                      <el-radio label="normal">正常</el-radio>
                      <el-radio label="bold">粗体</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-collapse-item>
                <!-- <el-collapse-item
                  title="样式"
                  name="2"
                >
                  <div class="panel-item ml20">
                    <div class="item-label">位置：</div>
                    <div class="position-inputs">
                      <el-input-number
                        v-model="elementX"
                        :min="0"
                        :max="originalWidth"
                        :step="1"
                        size="small"
                        @change="updateElementPosition('x')"
                      >
                        <template slot="prepend">X</template>
                      </el-input-number>
                      <el-input-number
                        v-model="elementY"
                        :min="0"
                        :max="originalHeight"
                        :step="1"
                        size="small"
                        @change="updateElementPosition('y')"
                      >
                        <template slot="prepend">Y</template>
                      </el-input-number>
                    </div>
                  </div>
                  <div class="panel-item ml20">
                    <div class="item-label">尺寸：</div>
                    <div class="size-inputs">
                      <el-input-number
                        v-model="elementWidth"
                        :min="20"
                        :max="originalWidth"
                        :step="1"
                        size="small"
                        @change="updateElementSize('width')"
                      >
                        <template slot="prepend">宽</template>
                      </el-input-number>
                      <el-input-number
                        v-model="elementHeight"
                        :min="20"
                        :max="originalHeight"
                        :step="1"
                        size="small"
                        @change="updateElementSize('height')"
                      >
                        <template slot="prepend">高</template>
                      </el-input-number>
                    </div>
                  </div>
                </el-collapse-item> -->
              </el-collapse>
            </el-form>
          </div>

          <!-- 图片、视频属性编辑 -->
          <div v-else-if="selectedElement.type === 'image' || selectedElement.type === 'file' || selectedElement.type === 'video'">
            <div class="right-title">
              {{ selectedElement.type === 'video' ? '视频' : '图片' }}：
            </div>
            <div
              v-if="!selectedElement.url"
              class="video-empty flex-column-center f-center"
              @click="handleReplace(selectedElement)"
            >
              <i class="iconfont icon-shangchuan"></i>
              <span>添加{{ selectedElement.type === 'video' ? '视频' : '图片' }}</span>
            </div>
            <div
              v-else
              class="material-container"
            >
              <video
                v-if="getTypeByUrl(selectedElement.url) === 'video'"
                :src="selectedElement.url"
                class="video-preview"
                controls
              ></video>
              <img
                v-else
                :src="selectedElement.url"
                class="video-preview"
              />
              <div
                class="deleteIcon"
                @click="removeMaterial"
              >
                <i class="iconfont icon-delete"></i>
              </div>
            </div>
            <!-- 位置控制 -->
            <div class="right-title mt32">样式：</div>
            <div class="panel-item ml20">
              <div class="item-label">位置：</div>
              <div class="position-inputs">
                <el-input-number
                  v-model="elementX"
                  :min="0"
                  :max="originalWidth"
                  :step="1"
                  size="small"
                  @change="updateElementPosition('x')"
                >
                  <template slot="prepend">X</template>
                </el-input-number>
                <el-input-number
                  v-model="elementY"
                  :min="0"
                  :max="originalHeight"
                  :step="1"
                  size="small"
                  @change="updateElementPosition('y')"
                >
                  <template slot="prepend">Y</template>
                </el-input-number>
              </div>
            </div>

            <!-- 尺寸控制 -->
            <div class="panel-item ml20">
              <div class="item-label">尺寸：</div>
              <div class="size-inputs">
                <el-input-number
                  v-model="elementWidth"
                  :min="20"
                  :max="originalWidth"
                  :step="1"
                  size="small"
                  @change="updateElementSize('width')"
                >
                  <template slot="prepend">宽</template>
                </el-input-number>
                <el-input-number
                  v-model="elementHeight"
                  :min="20"
                  :max="originalHeight"
                  :step="1"
                  size="small"
                  @change="updateElementSize('height')"
                >
                  <template slot="prepend">高</template>
                </el-input-number>
              </div>
            </div>
          </div>
          <div v-else-if="selectedElement.type === 'music'">
            <div class="right-title">
              音乐：
            </div>
            <el-button
              size="small"
              type="primary"
              @click="handleReplace(selectedElement)"
            >{{$t('tip.select')}}</el-button>
            <div
              v-if="musicUrl"
              class="flex-column"
            >
              <div
                class="flex-row-center"
                style="margin-top: 5px"
              >
                <audio
                  controls
                  :src="musicUrl"
                  class="f-grow"
                  ref="audioPlayer"
                ></audio>
                <i
                  class="el-icon-circle-close pointer ml5"
                  @click="delMusic"
                ></i>
              </div>
              <div class="flex-row-center mt5">
                <span style="color: #606266;font-size: 14px;margin-right: 12px;font-weight: 700;">{{ $t('editVideo.musicVolume') }}</span>
                <el-slider
                  :step="0.1"
                  class="f-grow"
                  v-model="gain"
                  :max="10"
                  :min="0"
                  show-input
                ></el-slider>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 素材选择抽屉 -->
    <material-drawer
      ref="materialDrawer"
      :visible.sync="materialDrawerVisible"
      @selectionConfirmed="handleMaterialSelected"
    />
    <el-dialog
      class="publicDialog"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="50%"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('issueItem.translateTo') }}</div>
          <i
            class="el-icon-close"
            @click="handleCancel"
          ></i>
        </div>
      </div>
      <el-select
        v-model="language"
        :placeholder="$t('issueItem.translateLanguage')"
        filterable
        clearable
        :default-first-option="true"
        style="width: 100%;"
      >
        <el-option
          v-for="dict in languageList"
          :key="dict.bdCode"
          :label="dict.language"
          :value="dict.bdCode"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancelBtn btnWidth120"
          @click="handleCancel"
        >{{$t('cancel')}}</el-button>
        <el-button
          class="submitBtn btnWidth120"
          :loading="loadingTranslate"
          @click="handleConfirm"
        >{{$t('confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";
import {
  getVideoTemplateDetail,
  videoTemplateGenerate,
} from "@/api/ai/template";
import { rewrite, translate } from "../../api/ai/language";
import { getLanguages } from "../../api/ai/ali";

export default {
  name: "Editor",
  dicts: ["font_typeface"],
  components: {
    MaterialDrawer,
  },

  directives: {
    draggable: {
      bind(el, binding, vnode) {
        const component = vnode.context;
        const element = binding.value; // 获取当前元素
        el.onmousedown = function (e) {
          // 如果点击的是按钮或输入框，不启动拖动
          if (
            e.target.tagName === "BUTTON" ||
            e.target.tagName === "INPUT" ||
            e.target.classList.contains("element-actions")
          ) {
            return;
          }
          component.selectElement(element);
          const currentElement = element;
          // 获取当前元素
          // const element = component.selectedElement;
          // if (!element || !element.cssObj) return;

          // 获取初始位置（不带px）
          const startX = e.clientX;
          const startY = e.clientY;
          const startLeft = parseFloat(currentElement.cssObj.x);
          const startTop = parseFloat(currentElement.cssObj.y);
          let hasMoved = false;

          function handleMouseMove(e) {
            hasMoved = true;
            const deltaX = e.clientX - startX;
            // currentElement.cssObj.x = newX.toFixed(2);
            const deltaY = e.clientY - startY;

            const scale = component.scale || 1;

            // 计算新位置
            const maxX =
              component.originalWidth -
              (parseFloat(currentElement.cssObj.width) || 0);
            const maxY =
              component.originalHeight -
              (parseFloat(currentElement.cssObj.height) || 0);

            const newX = Math.max(
              0,
              Math.min(maxX, startLeft + deltaX / scale)
            );
            const newY = Math.max(0, Math.min(maxY, startTop + deltaY / scale));

            // 更新元素位置（不带px）
            currentElement.cssObj.x = newX.toFixed(2);
            currentElement.cssObj.y = newY.toFixed(2);

            // 更新面板数据
            component.elementX = parseFloat(newX.toFixed(2));
            component.elementY = parseFloat(newY.toFixed(2));

            // 更新元素样式（需要带px）
            el.style.left = newX.toFixed(2) + "px";
            el.style.top = newY.toFixed(2) + "px";
          }

          function handleMouseUp() {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);

            // 只有在真正移动过时才添加历史记录
            if (hasMoved) {
              component.addHistory();
            }
          }

          document.addEventListener("mousemove", handleMouseMove);
          document.addEventListener("mouseup", handleMouseUp);

          e.preventDefault();
          e.stopPropagation();
        };
      },
    },
  },

  data() {
    return {
      loading: false,
      pageData: {
        id: null,
        code: "",
        title: "",
        width: 720,
        height: 1280,
        pageList: [],
      },
      originalWidth: 720,
      originalHeight: 1280,
      selectedElement: null, //选中的元素
      materialDrawerVisible: false,
      materialType: null,
      replacingElement: null,
      scale: 1,
      currentTool: "",
      tools: [
        { type: "text", name: "文字", icon: "icon-wenzi" },
        { type: "file", name: "图片", icon: "icon-tupian" },
        { type: "video", name: "视频", icon: "icon-shipin" },
        { type: "music", name: "音乐", icon: "icon-yinle" },
        // { type: "subtitle", name: "字幕", icon: "icon-zimu" },
      ],
      history: [],
      historyIndex: -1,
      maxFontSize: 200,
      minFontSize: 12,
      fontSizeStep: 2,
      maxHistorySteps: 20, // 最大历史记录步数
      elementX: null,
      elementY: null,
      elementWidth: null,
      elementHeight: null,
      isHistoryAction: false, // 新增：标记是否正在执行历史操作
      activeNames: ["1", "2"],
      languageList: [], //语言列表
      dialogVisible: false, //选择翻译语言
      language: "en", //需要翻译成的语言
      loadingTranslate: false, //选择翻译语言的确定按钮
      musicUrl: "", //音乐链接
      gain: 0.1, //音量
    };
  },

  computed: {
    canvasStyle() {
      return {
        width: this.originalWidth + "px",
        height: this.originalHeight + "px",
        backgroundColor: "#000000",
        transform: `scale(${this.scale})`,
        transformOrigin: "top center",
      };
    },
    canUndo() {
      return this.historyIndex > 0;
    },

    canRedo() {
      return this.historyIndex < this.history.length - 1;
    },

    canEditFont() {
      return (
        this.selectedElement &&
        (this.selectedElement.type === "text" ||
          (this.currentTool === "subtitle" &&
            this.selectedElement.type === "text"))
      );
    },
  },

  watch: {
    // 其他 watch 保持不变...
  },

  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getTemplateDetail();
    }
    this.getLanguageList();
  },

  mounted() {
    this.calculateScale();
    window.addEventListener("resize", this.calculateScale);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.calculateScale);
  },

  methods: {
    // 计算属性：处理输入内容并替换回车符为 <br/> 标签
    formattedContent(content) {
      return content ? content.replace(/\n/g, "<br/>") : "";
    },
    // 视频、图片移除当前元素的url（元素仍保留）
    removeMaterial() {
      this.selectedElement.url = null;
    },
    // 移除原数据中的px单位
    removePx(value) {
      return typeof value === "string" && value.endsWith("px")
        ? parseFloat(value)
        : value;
    },
    // 将rgba颜色转换为hex颜色
    rgbaToHex(rgba) {
      const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/);
      if (match) {
        const r = parseInt(match[1]).toString(16).padStart(2, "0");
        const g = parseInt(match[2]).toString(16).padStart(2, "0");
        const b = parseInt(match[3]).toString(16).padStart(2, "0");
        return `#${r}${g}${b}`.toUpperCase();
      }
      return rgba; // 如果不匹配，返回原值
    },
    // 单独处理fontweight
    processFontWeight(value) {
      if (!value) return "normal"; // 处理 null、undefined
      if (typeof value === "string") {
        if (!isNaN(value)) {
          // 如果是字符串但包含数值（如 "700"）
          return parseInt(value) > 500 ? "bold" : "normal";
        }
        // 直接是 "bold"、"normal" 等情况，返回原值
        return ["bold", "normal", "lighter", "bolder"].includes(value)
          ? value
          : "normal";
      }
      if (typeof value === "number") {
        // 处理数值格式的 font-weight
        return value > 500 ? "bold" : "normal";
      }
      return "normal"; // 兜底方案
    },
    processCssObj(cssObj) {
      if (!cssObj) return cssObj;
      const newCssObj = {};
      for (const key in cssObj) {
        let value = cssObj[key];
        if (typeof value === "string") {
          if (value.includes("px")) {
            value = this.removePx(value);
          } else if (value.includes("rgba")) {
            value = this.rgbaToHex(value);
          }
        }
        if (key === "font-weight") {
          value = this.processFontWeight(value);
        }
        newCssObj[key] = value;
      }
      return newCssObj;
    },
    // 获取翻译用的语言列表
    getLanguageList() {
      getLanguages().then((response) => {
        const newList = response.data.map((item) => {
          return { ...item, select: false };
        });
        const oftenZero = newList.filter((item) => item.isOften === 1);
        const others = newList.filter((item) => item.isOften !== 1);
        this.languageList = oftenZero.concat(others);
      });
    },
    //选择翻译语言
    chooseLanguage() {
      if (!this.selectedElement.content) {
        this.$modal.msgError("请先输入文本内容");
        return;
      }
      this.dialogVisible = true;
    },
    // 取消翻译
    handleCancel() {
      this.dialogVisible = false;
    },
    // 确认翻译语种
    handleConfirm() {
      if (!this.language) {
        this.$modal.msgError(this.$t("issueItem.tipTranslate"));
        return;
      }
      this.loadingTranslate = true;
      translate({
        text: this.selectedElement.content,
        to: this.language,
        from: "auto",
      })
        .then((response) => {
          this.dialogVisible = false;
          if (
            response.data &&
            response.data.trans_result &&
            response.data.trans_result.length > 0
          ) {
            let result = response.data.trans_result[0].dst;
            if (result) this.selectedElement.content = result;
          }
        })
        .finally(() => {
          this.loadingTranslate = false;
        });
    },
    //重写
    rewrite(type) {
      if (!this.selectedElement.content) {
        this.$modal.msgError(this.$t("issueItem.tipPostFirst"));
        return;
      }
      this.loadingTranslate = true;
      rewrite({
        content: this.selectedElement.content,
        type: type,
      })
        .then((res) => {
          this.selectedElement.content = res.data;
        })
        .finally(() => {
          this.loadingTranslate = false;
        });
    },
    getTemplateDetail() {
      this.loading = true;
      getVideoTemplateDetail(this.id)
        .then((res) => {
          let data = res.data;
          console.log("data", JSON.stringify(data));
          const newPageList = data.pageList.map((item) => ({
            ...item,
            content: item.content ? item.content.replace("<br/>", "\n") : "",
            cssObj: this.processCssObj(item.cssObj),
          }));
          this.pageData = {
            ...data,
            pageList: newPageList,
          };
          console.log("pageData", JSON.stringify(this.pageData));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    calculateScale() {
      const padding = 40;
      const availableHeight = window.innerHeight - 200;
      const scale = (availableHeight - padding) / this.originalHeight;
      this.scale = Math.min(scale, 1);
    },

    getElementStyle(element) {
      if (!element.cssObj) return {};

      const baseStyle = {
        position: "absolute",
        left: element.cssObj.x + "px",
        top: element.cssObj.y + "px",
      };

      // 图片和视频元素的样式
      if (
        element.type === "image" ||
        element.type === "video" ||
        element.type === "file"
      ) {
        return {
          ...baseStyle,
          width: element.cssObj.width + "px", // 需要加上px
          height: element.cssObj.height + "px", // 需要加上px
        };
      }

      // 文字元素的样式
      if (element.type === "text") {
        return {
          ...baseStyle,
          width: element.cssObj.width ? element.cssObj.width + "px" : "auto",
          height: element.cssObj.height ? element.cssObj.height + "px" : "auto",
          color: element.cssObj.color,
          fontSize: element.cssObj["font-size"] + "px",
          fontFamily: element.cssObj["font-family"],
          fontWeight: element.cssObj["font-weight"],
          textAlign: element.cssObj["text-align"],
          letterSpacing: element.cssObj["letter-spacing"] + "px",
          lineHeight: element.cssObj.height
            ? element.cssObj.height + "px"
            : element.cssObj["line-height"] + "px",
          whiteSpace: element.cssObj["white-space"] || "pre-wrap",
          textShadow: element.cssObj["text-shadow"],
          WebkitTextStroke: element.cssObj["-webkit-text-stroke"],
          overflowWrap: element.cssObj["overflow-wrap"],
        };
      }

      return baseStyle;
    },

    selectElement(element) {
      if (this.selectedElement && this.selectedElement.id === element.id) {
        return;
      }

      this.selectedElement = element;
      this.currentTool = element.type;

      // 确保 cssObj 存在
      if (!element.cssObj) {
        if (element.type === "text") {
          element.cssObj = {
            x: "0",
            y: "0",
            width: "auto",
            height: "auto",
            "font-size": "42",
            color: "#000000",
            "font-family": "SimSun",
            "font-weight": "normal",
            "text-align": "left",
            "letter-spacing": "0",
            "line-height": "1.5",
            "white-space": "nowrap",
            "overflow-wrap": "break-word",
          };
        } else {
          element.cssObj = {
            x: "0",
            y: "0",
            width: "100",
            height: "100",
          };
        }
      }

      // 更新面板数据
      this.elementX = parseFloat(element.cssObj.x);
      this.elementY = parseFloat(element.cssObj.y);

      // 对于非文本元素，更新宽高
      // if (element.type !== "text") {
      this.elementWidth = parseFloat(element.cssObj.width);
      this.elementHeight = parseFloat(element.cssObj.height);
      // }
    },

    handleReplace(element) {
      this.materialDrawerVisible = true;
      this.replacingElement = element;
      if (element.type === "video") {
        this.materialType = "video";
        this.$nextTick(() => {
          this.$refs.materialDrawer.$refs.materialLibrary.init(6); // 6 表示视频单选
        });
      } else if (element.type === "image" || element.type === "file") {
        this.materialType = "image";
        this.$nextTick(() => {
          this.$refs.materialDrawer.$refs.materialLibrary.init(3); // 3 表示视频单选
        });
      } else if (element.type === "music") {
        this.materialType = "music";
        this.$nextTick(() => {
          this.$refs.materialDrawer.$refs.materialLibrary.init(2); // 2 表示音频单选
        });
      }
    },

    handleMaterialSelected(items) {
      if (!items.length) return;
      const item = items[0];

      if (this.materialType === "music") {
        // this.pageData.music = {
        //   url: item.url,
        //   name: item.name,
        // };
        this.musicUrl = item.url;
      } else {
        if (this.replacingElement) {
          this.replacingElement.url = item.url;
          this.replacingElement.content = item.name;
          this.replacingElement.isReplaced = true;
          if (this.materialType === "video") {
            this.replacingElement.id = item.id;
          }

          // 如果是视频类型，确保替换的是视频文件
          // if (
          //   this.replacingElement.content.includes("video") &&
          //   !item.url.includes(".mp4")
          // ) {
          //   this.$message.error("请选择视频文件");
          //   return;
          // }

          this.replacingElement = null;
        }
      }

      this.materialDrawerVisible = false;
      this.addHistory();
    },
    delMusic() {
      this.musicUrl = "";
    },
    selectTool(type) {
      this.currentTool = type;

      if (type === "text" || type === "subtitle") {
        const newText = {
          id: Date.now(),
          type: "text",
          content: type === "text" ? "双击编辑文本" : "双击编辑字幕",
          cssObj: {
            x: "100", // 不带px
            y: type === "text" ? "100" : (this.originalHeight - 150).toString(),
            width: "auto",
            height: "auto",
            "font-size": "24",
            color: type === "text" ? "#000000" : "#ffffff",
            "font-family": "SimSun",
            "font-weight": "normal",
            "text-align": type === "text" ? "left" : "center",
            "letter-spacing": "0",
            "line-height": "1.5",
            "white-space": "nowrap",
          },
        };
        this.pageData.pageList.push(newText);
        this.selectedElement = newText;
        this.addHistory();
      } else if (type === "music") {
        this.materialType = "music";
        this.selectedElement = {
          type: "music",
        };
        // this.materialDrawerVisible = true;
      } else if (type === "video" || type === "image" || type === "file") {
        const firstElement = this.pageData.pageList.find((item) => {
          if (type === "video") {
            return item.type === "video";
          } else if (type === "image" || type === "file") {
            return item.type === "image";
          }
          return false;
        });

        if (firstElement) {
          // 如果找到了元素，选中它
          this.selectElement(firstElement);
        }
      }
    },

    // 转换元素数据为新格式
    convertElementToNewFormat(element) {
      // 计算相对位置和尺寸
      const base = {
        width: parseFloat(element.cssObj.width) / this.originalWidth,
        height: parseFloat(element.cssObj.height) / this.originalHeight,
        x: parseFloat(element.cssObj.x) / this.originalWidth,
        y: parseFloat(element.cssObj.y) / this.originalHeight,
        adaptMode: "Cover",
      };

      if (element.type === "text") {
        return {
          content: element.content,
          fixedFontSize: parseInt(element.cssObj["font-size"]),
          fontColor: element.cssObj.color || "#ffffff",
          fontSize: parseInt(element.cssObj["font-size"]),
          Alignment: this.getTextAlignment(base.y),
          sizeRequestType: "Nominal",
          font: element.cssObj["font-family"] || "Microsoft YaHei",
          x: base.x,
          y: base.y,
        };
      } else if (element.type === "video") {
        return {
          mediaUrls: [
            {
              url: element.url,
              id: element.id,
            },
          ],
          type: "video",
          ...base,
        };
      } else if (element.type === "image" || element.type === "file") {
        // 判断是否是视频类型
        const isVideo = this.getTypeByUrl(element.url) === "video";
        // 判断是否是模板自带的图片
        const isTemplateImage = !element.isReplaced && !isVideo;

        return {
          mediaUrls: [
            {
              url: element.url,
            },
          ],
          type: isTemplateImage ? "stickers" : "image",
          ...base,
          // 如果是图片类型，添加默认时长
          // ...{ duration: 3 },
        };
      }

      return null;
    },

    // 获取文本对齐方式
    getTextAlignment(y) {
      if (this.selectedElement.cssObj["Alignment"])
        return this.selectedElement.cssObj["Alignment"];
      if (y < 0.3) return "TopCenter";
      if (y > 0.7) return "BottomCenter";
      return "CenterCenter";
    },

    // 转换整个模板数据
    convertTemplateData() {
      const titles = [];
      const mediaList = [];
      let music = null;

      // 处理音乐
      if (this.musicUrl) {
        music = {
          url: this.musicUrl,
          volume: this.gain,
          speed: 1,
        };
      }

      // 处理页面元素
      this.pageData.pageList.forEach((element) => {
        if (element.type === "text") {
          const titleData = this.convertElementToNewFormat(element);
          if (titleData) {
            titles.push(titleData);
          }
        } else if (
          element.type === "image" ||
          element.type === "file" ||
          element.type === "video"
        ) {
          const mediaData = this.convertElementToNewFormat(element);
          if (mediaData) {
            mediaList.push(mediaData);
          }
        }
      });

      return {
        templateId: this.id,
        titles,
        mediaList,
        music,
      };
    },

    // 验证数据
    validateData(data) {
      // 检查视频元素
      const videoElements = data.mediaList.filter(
        (media) => media.type === "video"
      );

      // 如果有视频元素，检查是否都已替换
      if (videoElements.length > 0) {
        const hasUnreplacedVideo = videoElements.some(
          (media) => this.getTypeByUrl(media.mediaUrls[0].url) !== "video"
        );

        if (hasUnreplacedVideo) {
          this.$message.error("请先替换视频素材");
          return false;
        }
      }

      return true;
    },

    // 保存方法
    async handleSave() {
      try {
        const convertedData = this.convertTemplateData();

        // 验证数据
        if (!this.validateData(convertedData)) {
          return;
        }

        // 如果没有音乐，确保 music 为 null
        if (!convertedData.music || !convertedData.music.url) {
          convertedData.music = null;
        }

        await videoTemplateGenerate(convertedData);
        this.$modal.msgSuccess("视频任务创建成功");
        this.$tab.closeOpenPage({ path: "/videoManager/videoManager" });
      } catch (error) {
        console.error("保存失败:", error);
        this.$modal.msgError("保存失败 ");
      }
    },

    copyElement() {
      if (!this.selectedElement) return;

      const newElement = JSON.parse(JSON.stringify(this.selectedElement));
      newElement.id = Date.now();

      newElement.cssObj.x = parseFloat(newElement.cssObj.x) + 20 + "px";
      newElement.cssObj.y = parseFloat(newElement.cssObj.y) + 20 + "px";

      this.pageData.pageList.push(newElement);
      this.addHistory();
    },

    deleteElement() {
      if (!this.selectedElement) return;

      const index = this.pageData.pageList.findIndex(
        (item) => item.id === this.selectedElement.id
      );
      if (index > -1) {
        // 先记录删除前的状态
        const oldState = {
          pageList: JSON.parse(JSON.stringify(this.pageData.pageList)),
          selectedElementId: this.selectedElement.id,
        };

        // 执行删除操作
        this.pageData.pageList.splice(index, 1);
        this.selectedElement = null;

        // 添加到历史记录
        this.addHistory(oldState);
      }
    },

    enlargementFont() {
      if (!this.selectedElement || this.selectedElement.type !== "text") return;

      const currentSize = parseInt(this.selectedElement.cssObj["font-size"]);
      if (currentSize < this.maxFontSize) {
        this.selectedElement.cssObj["font-size"] =
          currentSize + this.fontSizeStep + "px";
        this.addHistory();
      }
    },

    reductionFont() {
      if (!this.selectedElement || this.selectedElement.type !== "text") return;

      const currentSize = parseInt(this.selectedElement.cssObj["font-size"]);
      if (currentSize > this.minFontSize) {
        this.selectedElement.cssObj["font-size"] =
          currentSize - this.fontSizeStep + "px";
        this.addHistory();
      }
    },

    addHistory(oldState) {
      if (this.isHistoryAction) return;

      // 保存完整的状态
      const state = {
        pageList: JSON.parse(JSON.stringify(this.pageData.pageList)),
        selectedElementId: this.selectedElement
          ? this.selectedElement.id
          : null,
        elementX: this.elementX,
        elementY: this.elementY,
        elementWidth: this.elementWidth,
        elementHeight: this.elementHeight,
      };

      // 删除当前位置之后的历史记录
      this.history = this.history.slice(0, this.historyIndex + 1);
      this.history.push(JSON.stringify(state));

      // 如果超出最大步数，删除最早的记录
      if (this.history.length > this.maxHistorySteps) {
        this.history = this.history.slice(1);
      }

      this.historyIndex = this.history.length - 1;
    },

    undo() {
      if (!this.canUndo) return;

      this.isHistoryAction = true;
      try {
        this.historyIndex--;
        const state = JSON.parse(this.history[this.historyIndex]);

        // 恢复页面元素
        this.pageData.pageList = JSON.parse(JSON.stringify(state.pageList));

        // 恢复选中状态
        if (state.selectedElementId) {
          this.selectedElement = this.pageData.pageList.find(
            (item) => item.id === state.selectedElementId
          );
        } else {
          this.selectedElement = null;
        }

        // 恢复面板数据
        this.elementX = state.elementX;
        this.elementY = state.elementY;
        this.elementWidth = state.elementWidth;
        this.elementHeight = state.elementHeight;
      } finally {
        this.$nextTick(() => {
          this.isHistoryAction = false;
        });
      }
    },

    redo() {
      if (!this.canRedo) return;

      this.isHistoryAction = true;
      try {
        this.historyIndex++;
        const state = JSON.parse(this.history[this.historyIndex]);

        // 恢复页面元素
        this.pageData.pageList = JSON.parse(JSON.stringify(state.pageList));

        // 恢复选中状态
        if (state.selectedElementId) {
          this.selectedElement = this.pageData.pageList.find(
            (item) => item.id === state.selectedElementId
          );
        } else {
          this.selectedElement = null;
        }

        // 恢复面板数据
        this.elementX = state.elementX;
        this.elementY = state.elementY;
        this.elementWidth = state.elementWidth;
        this.elementHeight = state.elementHeight;
      } finally {
        this.$nextTick(() => {
          this.isHistoryAction = false;
        });
      }
    },
    updateElementAlignment() {
      if (!this.selectedElement) return;
      this.$set(
        this.selectedElement.cssObj,
        "Alignment",
        this.selectedElement.cssObj["text-align"]
      );
      this.$set(this.selectedElement.cssObj, "x", 0);
      this.$set(
        this.selectedElement.cssObj,
        "width",
        this.originalWidth
      );
      this.addHistory();
    },
    updateElementPosition(type) {
      if (!this.selectedElement) return;

      const value = this[type === "x" ? "elementX" : "elementY"];
      const cssKey = type;
      const oldValue = parseFloat(this.selectedElement.cssObj[cssKey]);
      const newValue = parseFloat(value).toFixed(2);

      if (oldValue !== parseFloat(newValue)) {
        // 位置值不带 px
        this.selectedElement.cssObj[cssKey] = newValue;
        this.$forceUpdate();
        this.addHistory();
      }
    },

    updateElementSize(type) {
      if (!this.selectedElement) return;

      const value = this[type === "width" ? "elementWidth" : "elementHeight"];
      const cssKey = type;
      const oldValue = parseFloat(this.selectedElement.cssObj[cssKey]);
      const newValue = parseFloat(value).toFixed(2);

      if (oldValue !== parseFloat(newValue)) {
        // 尺寸值带 px
        this.selectedElement.cssObj[cssKey] = newValue;
        this.$forceUpdate();
        this.addHistory();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-container {
  //height: 100vh;
  // display: flex;
  // flex-direction: column;
  padding: 16px 24px;
  .editor-header {
    height: 54px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: #fff;
    // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .title {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;
    }
  }

  .editor-main {
    height: calc(100vh - 150px);
    flex: 1;
    display: flex;
    // padding: 20px;
    overflow: hidden;
    background: #f6f8fa;
    border-radius: 0px 14px 14px 14px;

    .editor-tools {
      width: 100px;
      overflow: auto;
      // padding: 10px;

      .tool-item {
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // border-radius: 4px;
        // margin-bottom: 10px;
        // transition: all 0.3s;

        .iconfont-tool {
          color: #0156ff;
          font-size: 24px;
          margin-bottom: 6px;
        }

        &:hover {
          background: #f5f7fa;
        }

        &.active {
          background: #edf1f5;
          color: #0156ff;
          border-left: 2px solid #016fee;
        }
      }
    }

    .editor-canvas {
      flex: 1;
      // margin: 0 20px;
      background: #edf1f5;
      height: calc(100vh - 150px);
      // border-radius: 4px;
      overflow: auto;
      display: flex;
      justify-content: center;
      padding: 20px;
      position: relative;
      .editor-canvas-settings {
        position: absolute;
        right: 25px;
        top: 25px;
        width: 60px;
        background: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        border: 1px solid #e5e7eb;
        padding: 10px 0;
      }
      .settings-item {
        color: #666666;
        font-size: 20px;
        margin: 10px 0;
        cursor: pointer;

        &.disabled {
          color: #c0c4cc;
          cursor: not-allowed;

          &:hover {
            color: #c0c4cc;
          }
        }

        &:not(.disabled):hover {
          color: #409eff;
        }
      }

      .canvas-container {
        position: relative;
        background: #000;
        transform-origin: top center;
        margin: auto;
        width: 720px; // 添加固定宽度
        min-width: 720px; // 确保最小宽度

        .element {
          position: absolute;
          cursor: move;
          box-sizing: border-box;

          &.active {
            // outline: 2px solid #409eff;
            border: 2px solid #409eff;
          }

          &.media-element {
            overflow: hidden;

            img,
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              pointer-events: none; // 防止媒体元素干扰拖动
            }

            video {
              background: #000; // 视频加载前的背景色
            }

            .element-actions {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: none;
              background: rgba(0, 0, 0, 0.5);
              padding: 10px;
              border-radius: 4px;
              z-index: 1;
            }

            &:hover .element-actions {
              display: block;
            }
          }

          &.text-element {
            user-select: none;
            min-width: 20px;
            min-height: 20px;
            padding: 4px 8px;
          }
        }
      }
    }

    .editor-panel {
      width: 450px;
      // background: #fff;
      // border-radius: 4px;
      padding: 24px;
      overflow: auto;

      .el-form-item {
        margin-bottom: 18px;
      }

      .panel-item {
        padding: 12px 0;

        .item-label {
          width: 56px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          text-align: left;
          font-style: normal;
          margin-bottom: 8px;
        }

        .position-inputs,
        .size-inputs {
          display: flex;
          gap: 10px;

          .el-input-number {
            width: calc(50% - 5px);
          }
        }
      }
    }
  }
}
.el-form--label-top .el-form-item__label {
  padding: 0;
}
.right-title {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}
.textInput {
  width: 100%;
  position: relative;
  .importText {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: -30px;
    color: #003bff;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #003bff;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    .icon-xialajiantou {
      margin-right: 8px;
    }
  }
  .flexInputBtn {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 8px;
    left: 8px;
    width: calc(100% - 20px);
    .btnBox {
      cursor: pointer;
      width: 58px;
      height: 24px;
      background: #eeeeee;
      border-radius: 2px;
      color: #0156ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #0156ff;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-right: 8px;
      .iconfont {
        font-size: 12px;
        margin-right: 5px;
      }
    }
  }
  ::v-deep .el-textarea__inner {
    padding-bottom: 40px; /* 留出空间给按钮 */
    box-sizing: border-box;
  }
}
.el-collapse {
  background-color: transparent !important;
}

::v-deep .el-collapse-item__header {
  background-color: transparent !important;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

::v-deep .el-collapse-item__content {
  background-color: #f6f8fa !important;
}
.video-empty {
  width: 396px;
  height: 222px;
  max-height: 222px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 17px;
  font-style: normal;
  cursor: pointer;
  .icon-shangchuan {
    color: #0156ff;
    font-size: 18px;
  }
}
.video-preview {
  width: 396px;
  height: 222px;
  border-radius: 12px;
  object-fit: cover; /* 确保视频不会拉伸 */
}
.material-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .deleteIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    background: #000000;
    border-radius: 8px;
    opacity: 0.5;
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
    .icon-delete {
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>
